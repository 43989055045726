@import url(./App/Fonts/style.css);
// body {
// width: unset !important;
// overflow: unset !important;
// }


html {
  --scrollbarBG: #f1f1f1;
  --thumbBG: #c1c1c1;
}
body::-webkit-scrollbar {
  width: 11px;
  opacity: 0;
}
body {
  // scrollbar-color: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  // width: unset !important;
  // overflow: unset !important;
}
body.ant-scrolling-effect {
  position: fixed;
  overflow-y: scroll !important;
  width: 100% !important;
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.ant-modal-wrap::-webkit-scrollbar {
  width: 11px;
}
.ant-modal-wrap {
  // scrollbar-color: auto;
  scrollbar-width: thin;
  scrollbar-color: #dbdbdb00 #dbdbdb00;
}
.ant-modal-wrap::-webkit-scrollbar-track {
  background: #dbdbdb00;
}
.ant-modal-wrap::-webkit-scrollbar-thumb {
  background-color: #dbdbdb00;
  border-radius: 6px;
  border: 3px solid #dbdbdb00;
}

.main-layout-style {
  min-height: 100vh;
}

.site-content {
  padding: 24px;
  background: #ffffff;
}

.ant-layout-header {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 33px;
}

.ant-layout-header .anticon {
  font-size: 20px;
}

.logo {
  img {
    width: 100%;
    max-width: 115px;
    // margin-left: 20px;
    padding: 30px 60px 30px 0;
  }
}

.auth-page {
  background: #f2f2f2;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-attachment: fixed;
  background-image: url("./../statics/images/Login.jpg");
}

.auth-page:after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.auth-page .ant-form {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.auth-page .ant-form-item {
  margin-bottom: 0;
}

.auth-page .form-title {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 20px;
}

.auth-page .main-wrap {
  z-index: 100;
  width: 100%;
  max-width: 450px;
}

.auth-page {
  .slection-asset-view {
    margin-bottom: 5px !important;
  }
}

.authentic-heading {
  text-align: center;
  margin-bottom: 100px;
}

.authentic-heading h1,
.authentic-heading h4 {
  color: #ffffff;
}

.authentic-heading h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
}

.authentic-heading h4 {
  font-weight: 100;
  text-transform: uppercase;
  opacity: 0.8;
}

.user {
  float: right;
  width: 15%;
  text-align: right;
}

.action-table .anticon {
  cursor: pointer;
}

.meta-avatar-align {
  margin: 0 !important;
  display: flex;
  align-items: center;
}

.meta-avatar-align .ant-card-meta-avatar {
  padding-right: 8px;
}

.meta-avatar-align .ant-card-meta-title {
  font-size: 14px;
  font-weight: 400;
  color: #595959;
}

.meta-link-avatar {
  cursor: pointer;
}

.meta-link-avatar:hover .ant-card-meta-title {
  color: #ff2d6c;
}
/*******  Asset Style  ***********/
.header-style {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.header-search {
  width: 50%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}
.header-search form {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.asset-search .ant-form-item {
  margin: 0;
}
.asset-search {
  width: 100%;
  position: relative;
  display: flex;
}
.asset-search .ant-form-item-control-input-content {
  display: flex;
}
.asset-search .ant-form-item {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.asset-search .ant-col {
  min-width: 100%;
}
.asset-search button {
  padding: 6px 30px 0 15px;
}
.form-card {
  width: 700px;
  padding: 30px;
}
.form-card .ant-select {
  width: 100%;
}
.form-card .ant-col {
  margin-bottom: 15px;
}
.asset-drop-icon {
  position: absolute;
  right: 10px;
  top: 0;
  height: 45px;
  cursor: pointer;
}
.asset-drop-icon span {
  font-size: 12px !important;
  color: #ffffff;
}
.asset-list img {
  max-width: 100px;
  margin-right: 20px;
}
.asset-list h4 {
  font-size: 16px;
  margin-bottom: 5px;
}
.asset-list h4 a {
  font-size: 16px;
  font-weight: bold;
  color: #444444;
}
.asset-list span {
  color: #FF246E;
  font-size: 10px;
}
.asset-list .asset-list-des {
  height: 100%;
  display: flex;
  align-items: center;
}
.asset-detail-style {
  display: flex;
  align-items: flex-start;
  min-height: 100vh;
  margin-top: 75px;
  // .form-section {
  //   margin-right: 27rem;
  // }
}

.asset-detail-style .form-section {
  width: 100%;
}

.asset-detail-style .right-side-section {
  width: 500px;
  margin-left: 30px;
  padding-left: 20px;
  border-left: solid 1px solid #ffffff #f1f1f1;
  max-width: 500px;
  background-color: #ffffff;
  box-shadow: 0px 0px 16px 1px #cdcdcd;
  border-radius: 6px;
  padding: 30px 40px 40px;
}

.custom-timeline {
  align-items: flex-start;
  .asset-checklist {
    font-size: 22px;
    font-weight: 500;
    margin-left: -11px;
    margin-bottom: 15px;
  }
}

.custom-timeline .vertical-bar {
  width: 7px;
  background-color: #f1f1f1;
  min-height: 100vh;
}

.list-of-bullets {
  margin-left: -12px;
  width: 100%;
}

.list-of-bullets .item-wrapper {
  margin-bottom: 25px;
}

.list-of-bullets .item {
  display: flex;
  align-items: center;
}

.list-of-bullets .bullet {
  width: 17px;
  height: 17px;
  background-color: #dbdbdb;
  margin-right: 10px;
  border-radius: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  .anticon {
    color: #ffffff;
    font-size: 10px;
  }
}

.list-of-bullets .text {
  color: #000;
  font-size: 16px;
}

.list-of-bullets .bullet.active {
  background: transparent linear-gradient(270deg, #ff5e5e 0%, #ff246e 100%) 0%
  0% no-repeat padding-box;

}
.asset-t-video {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.asset-t-lang
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #FF246E;
  color: #ffffff;
}
.asset-t-lang .ant-radio-button-wrapper {
  width: 50px;
  text-align: center;
  margin: 2px 12px;
  display: flex;
}
.asset-t-lang a {
  font-size: 11px;
  padding: 0 10px;
  display: block;
  margin-left: 10px;
  width: 40px;
  height: 27px;
  margin-bottom: 5px;
  border: 1px solid;
  border-radius: 2px;
  text-align: center;
  line-height: 24px;
}
.asset-t-lang a:hover,
.asset-t-lang a:active,
.asset-t-lang a:focus {
  background-color: #ff2d6c;
  color: #ffffff;
  border: 1px solid #ff2d6c;
}
.asset-t-lang a.active {
  background-color: #ff2d6c;
  color: #ffffff;
  border: 1px solid #ff2d6c;
}
.asset-t-lang button:last-child {
  margin-bottom: 0px;
}
.asset-text-title {
  margin-bottom: 15px;
}
.asset-text-cont ul {
  padding-left: 17px;
  margin-right: 20px;
}
.asset-text-title h4,
.asset-text-title h5,
.asset-text-title h6 {
  margin: 0;
}
.asset-text-title h3 .anticon svg {
  display: inline-block;
  font-size: 16px;
  color: #4a90ff;
  padding-left: 5px;
}
.add-read-btn button {
  margin-right: 10px;
  font-size: 12px;
}
/******* Asset Detail Style ******/
.asset-crousel {
  min-width: 100%;
  max-width: 100%;
  padding-right: 50px;
}
.asset-crousel .ant-carousel .slick-slide {
  text-align: center;
  height: 215px;
  line-height: 160px;
  overflow: hidden;
}
.asset-crousel .ant-carousel .slick-slide img {
  width: 100%;
}
ul.capter-list {
  padding: 10px 20px 10px 30px;
  margin: 0;
  list-style: none;
}
ul.capter-list li {
  position: relative;
}
ul.capter-list li::before {
  content: "";
  width: 6px;
  height: 1px solid #ffffff;
  position: absolute;
  left: -14px;
  background: black;
  top: 11px;
}
.asset-vendor span {
  margin-right: 10px;
}
.asset-vendor h4,
.asset-vendor h3 {
  margin: 0;
  line-height: 20px;
}
.asset-text-cont {
  margin-bottom: 20px;
}
.asset-text-cont .w-3 div {
  width: 33.333%;
}
.movie-poster-meta {
  line-height: 22px;
}

.movie-poster-meta .ant-card-meta-avatar {
  width: 150px;
}

.movie-poster-meta .ant-avatar-image {
  width: 100%;
  border-radius: 0;
  height: auto;
}
/***** Dashboard Style ******/
.scroll-sec {
  height: 236px;
  overflow: auto;
}
.rep-receive svg {
  font-size: 24px;
  color: #061529;
}
.rep-receive .ant-list-item-meta-title {
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 12px;
}
.d-card-header span {
  float: right;
}
.d-card-header span:hover {
  color: #ff2d6c;
}
.asset-trailer .iframe-w {
  width: 100%;
  height: 250px;
}
.dashboard-chart svg {
  font-size: 24px;
  height: 250px !important;
}
.dashboard-chart {
  max-height: 236px;
}
.ant-modal-wrap,
.ant-select-dropdown {
  z-index: 1000;
}
/**********         Custom Scroll Style         *************/
/* Let's get this party started */
.scroll-sec::-webkit-scrollbar {
  width: 4px;
  margin-left: 10px;
  display: none;
}
.ant-card-body:hover .scroll-sec::-webkit-scrollbar {
  display: block;
}

/* Track */
.scroll-sec::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
.scroll-sec::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(240, 242, 245, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.scroll-sec::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(203, 203, 204, 0.6);
}
.license-contract {
  margin-top: 85px;
  height: calc(100vh - 105px);
  overflow-y: scroll;
  border-bottom: solid 20px #ffffff;
  &::after {
    content: "";
    width: 2px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
  .contract-header {
    .ant-col-10 {
      max-width: none;
    }
  }
}
.license-contract::-webkit-scrollbar {
  width: 4px;
  height: 60px;
  margin-left: 10px;
}
.license-contract::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.license-contract::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 30px;
  background: rgba(240, 242, 245, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.license-contract::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(203, 203, 204, 0.6);
}

.checkout-box-group {
  padding: 20px;
  border: solid 1px solid #ffffff #ececec;
  border-radius: 10px;
}

.checkout-box-group .ant-checkbox-wrapper {
  margin-bottom: 15px;
}
.chat-style {
  .message-box {
    padding: 0 15px;
    border-radius: 10px 10px 0 10px;
    margin-bottom: 10px;
    max-width: 488px;
    background: transparent linear-gradient(270deg, #ff5e5e 0%, #ff246e 100%) 0%
    0% no-repeat padding-box;
    .ant-comment-content-detail {
      p {
        color: #ffffff;
      }
    }
  }
  .my-username {
    display: block;
    font-size: 12px;
    color: #292929;
    margin-bottom: 2px;
  }
}
.my-message-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}

.from-message-item {
  justify-content: flex-start !important;
  text-align: left !important;
}

.chat-style .messages-list {
  padding: 20px;
  overflow-y: scroll;
  height: 36vh;
}
.author-time-role {
  text-align: center;
  margin: 15px 5px 5px;
  h5 {
    margin: 0;
  }
  p {
    margin: 0;
    line-height: 14px;
  }
}
/* width */
.chat-style .messages-list::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.chat-style .messages-list::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
.chat-style .messages-list::-webkit-scrollbar-thumb {
  background: #bebebe;
  border-radius: 33px;
}

/* Handle on hover */
.chat-style .messages-list::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}
.send-in-icon {
  text-align: center;
  svg {
    max-width: 120px;
  }
}
/******     Organization Style   ************/
.org-detail {
  margin-bottom: 40px;
}
.org-detail img {
  width: 100%;
}
.auction-box {
  border: 1px solid #dbdcde;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 20px;
}
.auction-box .checkout-box-group {
  margin-right: 20px;
  width: 100%;
}
.top-bar-actions {
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: flex-end;
}
.notification-header {
  margin-bottom: -15px;
  .radio-btn-style {
    border-bottom: 0px;
    margin: 0;
  }
  .ant-radio-button-wrapper {
    height: 34px;
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
  }
}
.top-bar-notification {
  cursor: pointer;
  padding-top: 0px;
  display: flex;
  .ant-avatar {
    height: 21px;
    overflow: visible;
  }
}
.ant-popover-title {
  border: 0px;
}
.top-bar-notification {
  svg {
    font-size: 18px;
    width: 19px;
  }

  .ant-avatar.ant-avatar-icon {
    background: transparent;
  }
}
.notification-avatar {
  width: 60px;
  height: 60px;
  background: #ff4664;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-avatar-image {
    width: 100%;
    height: 100%;
  }
  svg {
    width: 20px;
    height: 20px;
    fill: #ffffff;
  }
}
.top-bar-notification .ant-badge-count {
  top: 3px;
  right: 5px;
  z-index: auto;
  min-width: 22px;
  height: 17px;
  padding: 0 5px;
  color: #fff;
  font-weight: 400;
  font-size: 10px !important;
  line-height: 17px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  box-shadow: 0 0 0 1px solid #fff #fff;
  width: 18px;
}

.top-bar-notification .ant-scroll-number-only > p.ant-scroll-number-only-unit {
  color: #ffffff;
}

.notifications-list-style {
  width: 450px;
  max-height: 320px;
  overflow-y: scroll;
  margin: 0 -20px;
  .ant-list-item {
    padding-left: 20px !important;
    padding-right: 30px !important;
    &:nth-child(1) {
      background-color: #fef0f0;
      border-bottom: 1px solid#ffd9db;

    }
    &:nth-child(2) {
      background-color: #fef0f0;
    }
    .ant-list-item-meta-title {
      margin: 0;
      p {
        margin: 0;
        color: #000;
        font-weight: 400;
        line-height: 14px;
      }
    }
    .ant-list-item-meta-description {
      font-size: 12px;
    }
    .ant-list-item-meta {
      display: flex;
      align-items: center;
    }
  }
}

.notifications-list-style::-webkit-scrollbar {
  width: 4px;
  margin-left: 10px;
  display: none;
}
.notifications-list-style::-webkit-scrollbar {
  display: block;
  width: 4px;
}

.notifications-list-style .ant-list-item {
  cursor: pointer;
  padding: 10px;
}

.notifications-list-style .ant-list-item:hover {
  background: #f1f1f1;
}

/* width */
.notifications-list-style::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.notifications-list-style::-webkit-scrollbar-track {
  background: #f8f8f8;
  border-radius: 33px;
}

/* Handle */
.notifications-list-style::-webkit-scrollbar-thumb {
  background: #dbdbdb !important;
  border-radius: 33px !important;
}

/* Handle on hover */
.notifications-list-style::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 33px;
}

.asset-full-form-style {
  height: calc(100vh - 30px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 15px 10px 0;
  padding-right: 25px;
}

/* Scrollbar */
.asset-full-form-style::-webkit-scrollbar {
  width: 4px;
  margin-left: 10px;
  display: none;
}

/* Handle */
.asset-full-form-style::-webkit-scrollbar-thumb {
  background: #bebebe;
  border-radius: 33px;
}

.asset-full-form-style::-webkit-scrollbar {
  display: block;
}

/* Track */
.asset-full-form-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.pencil-edit {
  cursor: pointer;
  color: #000000;
  opacity: 0.4;
  font-size: 20px;
}

.pencil-edit:hover {
  opacity: 0.9;
}

/////////////////////////////////////     New Style Start Here     /////////////////////////////////////////////////////

.ant-menu-inline-collapsed .ant-menu-item {
  margin: 0px !important;
  .ant-menu-sub {
    .ant-menu-item {
      margin: 0px !important;
    }
  }
}
.ant-menu-inline {
  border-width: 0px;
  background-color: white !important;
}
.ant-menu-inline .ant-menu-submenu {
  margin: 0 8px;
  width: auto;
  border-radius: 4px;
}
.ant-menu-submenu-active {
  .ant-menu-submenu-title {
    border-radius: 4px;
  }
}
.ant-menu-submenu-selected {
  .ant-menu-submenu-title {
    border-radius: 4px;
    margin-bottom: 10px;
  }
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: transparent;
}
.ant-menu {
  .ant-menu-item {
    margin: 0 8px;
    width: auto;
    border-radius: 4px;
  }
}
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after {
  background-color: #ff246e;
  background-image: none;
  opacity: 1;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  display: none;
}
///// Table Style /////
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.ant-table-tbody > tr > td {
  border-bottom: 0px;
  padding: 5px 16px;
}

/*****************/
/* width */
.table-style::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

/* Track */
.table-style::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 33px;
}

/* Handle */
.table-style::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 33px;
}

/* Handle on hover */
.table-style::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}
/*****************/

.table-style {
  overflow-x: auto;
  overflow-y: hidden;
  .action-table {
    .ant-btn {
      padding: 0;
    }
    .ant-space-item {
      margin: 0;
    }
  }
  .table-action {
    button {
      padding: 0;
      span {
        text-decoration: underline;
      }
    }
  }
  .ant-table {
    margin-bottom: 30px;
  }
  .ant-tag {
    border-radius: 6px;
    background-color: transparent;
    padding: 0 10px;
  }

  .ant-table-thead {
    tr {
      th {
        text-transform: uppercase;
        padding: 14px 16px;
      }
    }
  }
}
/***** Border Radius ******/
.table-row-dark:first-child,
.ant-table-tbody > tr > td:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.table-row-dark:last-child,
.ant-table-tbody > tr > td:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
// .ant-table-tbody > tr > td:last-child {
//   padding-right: 10px;
// }
.ant-table-thead > tr > th:last-child {
  width: 170px;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-item,
.ant-input,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-multiple .ant-select-selector {
  border-radius: 6px;
}
.ant-picker-range {
  border-radius: 6px;
}
.ant-pagination-prev {
  margin-right: 20px;
}
.ant-pagination-next {
  margin-left: 12px;
}
///////  Inventory Style   ///////
.my-market-inventory {
  display: flex;
  justify-content: space-between;
  padding: 15px 24px 15px;
  margin: -24px;
  margin-bottom: 26px;
  align-items: center;
  z-index: 100;
  background-color: #ffffff;
  .inventory-bottom-filter {
    margin-bottom: 0;
  }
  h3 {
    margin: 0;
    font-size: 28px;
    display: flex;
    align-items: center;
    svg {
      margin-left: 8px;
    }
    .anticon {
      color: #ff246e;
      margin-left: 8px;
    }
    span {
      svg {
        font-size: 20px;
        margin-right: 8px;
      }
    }
  }
}
.inventory-search-result {
  padding: 0 0 20px;
  h3 {
    font-size: 20px;
    margin-bottom: 0px;
  }
  .inventory-search-tabs {
    display: inline-flex;
    margin-right: 10px;
    padding: 10px 0;
    .inventory-result {
      button {
        height: 9px;
        margin-left: 7px;
        display: flex;
        svg {
          width: 8px;
          height: 8px;
        }
      }
    }
    span {
      border-radius: 4px 0 0 4px;
      padding: 4px 10px;
      font-weight: 300;
      font-size: 12px;
      display: flex;
      align-items: center;
      color: #888888;
    }
    .inventory-result {
      border-radius: 0px 4px 4px 0px;
      padding: 0px;
      font-weight: 700;
      border-left: 0;
      margin: 0px;
      span {
        &:last-child {
          border-right: 0px solid #dbdbdb !important;
        }
        border-right: 1px solid #dbdbdb;
        border-bottom: 0px;
        border-left: 0px;
        border-top: 0px;
        padding: 0 7px;
        height: 26px;
        font-weight: 500;
        color: #292929;
        svg {
          margin-left: 5px;
          fill: #292929;
          font-size: 6px;
          width: 7px;
          height: 7px;
        }
      }
    }
  }
}
.inventory-bottom-filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: center;
}
.inventry-switch-btns {
  display: flex;
  span {
    display: inline-flex;
    align-items: center;
    margin-right: 20px;
    color: #000000;
    button {
      margin-right: 5px;
    }
  }
}
.ant-switch-checked {
  background-color: rgba(0, 0, 0, 0.25);
}
.ant-switch {
  height: 8px;
  .ant-switch-handle {
    width: 14px;
    height: 14px;
    left: 0px;
    top: -3px;
    &::before {
      box-shadow: 0 0px 4px 0 rgba(0, 35, 11, 0.2);
    }
  }
}
.inventory-select-filter {
  max-width: 380px;
  min-width: 380px;
  .ant-btn-block {
    margin: 20px 0;
  }
  .ant-row {
    margin-bottom: 0;
  }
  .select-width {
    width: 160px;
  }
  .input-width {
    width: 190px;
  }
  .ant-space {
    justify-content: space-between;
    // align-items: center;
  }
  button {
    width: 100%;
  }
  .ant-picker {
    border: 1px solid #888888;
  }
}
.ant-popover-inner {
  border-radius: 6px;
  .ant-popover-inner-content {
    padding: 20px;
  }
}
.inventory-search-bar {
  width: 420px;
  display: flex;
  svg {
    margin-right: 10px;
    cursor: pointer;
  }
  .ant-btn > span {
    display: inline-flex;
  }
  button {
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0px;
    background-color: transparent !important;
  }
  .ant-input-affix-wrapper {
    font-size: 16px;
    border-radius: 6px;
    padding: 2px 11px;
    height: 40px;
    .ant-input {
      font-size: 12px;
    }
  }
}
.layout-btn {
  button {
    line-height: 41px;
    svg {
      width: 20px;
      fill: none;
      stroke: #888;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 1.5px;
    }
  }
  .editor-gyer:hover {
    background-color: #ff246e;
    svg {
      stroke: #ffffff;
    }
  }
  .ant-radio-button-wrapper {
    border-radius: 6px;
    width: 40px;
    height: 40px;
    line-height: 50px;
    padding: 0;
    text-align: center;
    span {
      svg {
        width: 22px;
      }
    }
  }
  .ant-radio-button-wrapper-checked {
    span {
      svg {
        width: 22px;
      }
    }
  }
  button {
    border-radius: 6px;
    width: 40px;
    height: 40px;
  }
}
.ant-btn {
  border-radius: 6px;
}
.radio-btn-style {
  display: flex;
  margin-left: 10px;
}
/**  Inventory Grid Style **/
.inventory-grid {
  .style-image-grid img {
    max-height: 290px;
    overflow: hidden;
    height: 100%;
    display: flex;
    background: #fafafa;
    border-radius: 7px;
  }
  &:hover {
    img {
      box-shadow: 0px 0px 5px 3px #e8e8e8;
    }
  }
  width: 33.333%;
  img {
    width: 100%;
    border-radius: 7px;
    // box-shadow: 0px 0px 7px 2px #dbdbdb;
    margin-bottom: 10px;
    transition-duration: 0.3s;
  }
  h3 {
    font-size: 21px;
    margin-bottom: 10px;
  }
  p {
    font-size: 13px;
    margin-bottom: 2px;
    span {
      font-weight: 600;
      margin: 0;
    }
  }
}
.lang-btn {
  .ant-radio-button-wrapper {
    display: block;
    margin: 0 0 10px 0;
    border-radius: 5px;
    text-transform: uppercase;
  }
}
.ant-menu {
  li {
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      margin-right: 15px;
    }
  }

  li.dashboard {
    svg {
      width: 14px;
    }
  }

  li.ant-menu-submenu {
    display: block;

    .ant-menu-submenu-title {
      display: flex;
      align-items: center;
    }
  }
}

.login-user-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  .text {
    text-align: right;
    margin-right: 10px;
    span {
      display: block;
      line-height: 19px;
    }
    .name {
      font-weight: bold;
      font-size: 15px;
      text-transform: capitalize;
    }
    .time {
      font-size: 13px;
      opacity: 0.7;
    }
  }

  .ant-avatar {
    width: 47px;
    height: 47px;
    // margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/////////////////    Detial Model Style    ///////////////////
.ant-modal {
  padding: 20px 0px;
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
}
.detail-model {
  min-width: 850px;
  .ant-modal-content {
    border-radius: 6px;
    .ant-modal-close-x {
      border-radius: 100px;
      background-color: rgba(0, 0, 0, 0.2);
      color: #ffffff;
      font-size: 14px;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      margin-top: 20px;
      margin-right: 20px;
    }
  }
  .ant-modal-body {
    padding: 0;
    .asset-detail-model {
      .asset-model-video {
        img {
          border-radius: 6px 6px 0 0;
        }
        position: relative;
        svg {
          position: absolute;
          width: 100%;
          height: 80px;
          top: 50%;
          left: 50%;
          margin-left: -38px;
          margin-top: -38px;
          cursor: pointer;
          transition-duration: 0.6s;
          &:hover {
            transform: scale(1.1);
          }
        }
        .react-video {
          width: 100% !important;
          height: 475px !important;
        }

        .anticon {
          position: absolute;
          width: 85px;
          height: 85px;
          font-size: 85px;
          border-radius: 100px;
          color: #ffffff;
          top: 50%;
          left: 50%;
          margin-left: -42px;
          margin-top: -42px;
        }
      }
      iframe {
        border-radius: 6px 6px 0 0;
      }
      .asset-model-info {
        padding: 35px 40px 20px;
        display: flex;
        .lang-btn {
          margin-right: 20px;
          .ant-radio-button-wrapper {
            width: 44px;
            height: 25px;
            line-height: 25px;
            padding: 0;
            align-items: center;
            justify-content: center;
            display: flex;
            font-size: 12px;
            border-radius: 6px;
          }
        }
        .asset-model-text {
          max-width: 345px;
          margin-right: 30px;
          min-width: 345px;
          h3 {
            font-size: 30px;
            line-height: 30px;
          }
          h4 {
            font-size: 22px;
            font-weight: 500;
          }
          p {
            font-size: 16px;
          }
          .duration {
            color: #888888;
            font-size: 16px;
            font-weight: 500;
            span {
              font-size: 18px;
              color: #ff336a;
              margin-right: 15px;
              font-weight: 500;
            }
          }
        }
        .asset-model-func {
          ul {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
              font-size: 16px;
              color: #888888;
              display: flex;
              margin-bottom: 12px;
              span {
                font-weight: 500;
                min-width: 145px;
                max-width: 145px;
              }
            }
          }
        }
      }
      .asset-model-btn {
        padding: 0px 102px 43px;
        display: flex;
        .ant-btn {
          padding: 12px 33px;
          height: auto;
          margin-right: 15px;
          span {
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.modal-style {
  .ant-modal-content {
    border-radius: 6px;

    .ant-modal-close {
      top: 12px;
      right: 12px;
      .ant-modal-close-x {
        border-radius: 100px;
        background: #dddddd;
        color: #292929;
        font-size: 14px;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .ant-modal-header {
    border: 0;
    padding-bottom: 0;
    border-radius: 6px;
    .ant-modal-title {
      margin-top: 10px;
      font-size: 22px;
    }
  }
}
/////////   Add to selection model     ///////////
.add-selection-filter {
  h2 {
    font-size: 26px;
    margin-bottom: 19px;
  }
  .ant-input-affix-wrapper {
    border-radius: 6px;
  }
}
.reset-default-btn {
  position: absolute;
  left: 195px;
  top: -2px;
  color: #ff246e;
}
.reset-default-btn:hover,
.reset-default-btn:active,
.reset-default-btn:focus {
  color: #292929;
}
.table-edit-row {
  display: flex;
  position: relative;
  .table-edit-column {
    width: 50%;

    .table-edit-column-title {
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .table-edit-task {
      background: #dbdbdb;
      padding: 12px;
      display: flex;
      font-weight: 500;
      border-radius: 5px;
      margin-bottom: 10px;
      line-height: 19px;

      .drag-icon {
        color: #888888;
        margin-right: 20px;

        .anticon {
          font-size: 20px;
          width: 6px;
        }
      }

      .title {
        font-size: 14px;
        color: #292929;
        text-transform: uppercase;
      }

      &:hover {
        background: #e2e2e2;
      }
    }
  }

  .swap-icon {
    padding: 0 15px 0 4px;
    font-size: 25px;
    display: flex;
    align-items: center;
  }
}

.column-tasks-area {
  min-height: 300px;

  height: 333px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 7px;
  .ant-radio-button-wrapper {
    border: 0px;
  }
  .ant-radio-button-wrapper-checked {
    color: #ff246e;
  }
}

.table-edit-column {
  &:last-child {
    .column-tasks-area {
      padding-right: 10px;
    }
  }
}

.radio-slection {
  width: 360px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .radion-s-icon {
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
  p {
    margin: 0 10px 0 0;
  }
}
.selection-radio {
  padding: 0;
  margin: 0;
  margin-bottom: 25px;
  list-style: none;
  max-height: 233px;
  height: 233px;
  min-height: 233px;
  overflow-y: auto;
  padding: 10px 0;
  li {
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-radio-group {
      width: 100%;
      .ant-radio-wrapper {
        display: flex;
        margin: 0;
        align-items: center;
        justify-content: space-between;
        span {
          padding-right: 0px;
        }
        &::after {
          display: none;
        }
      }
    }
    .ant-radio-wrapper {
      font-size: 14px;
      color: #888888;
      display: flex;
      align-items: center;
    }
    .anticon {
      svg {
        color: #292929;
        margin-right: 8px;
      }
    }
  }
}
.add-selections-style {
  .ant-btn {
    border-radius: 6px;
    padding: 8px 0;
    height: auto;
    width: 100%;
    margin-bottom: 20px;
  }
  .ant-btn-primary {
    margin-bottom: 5px;
  }
}
.add-select-update {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .ant-radio-wrapper {
    display: block;
  }
  .ant-btn {
    width: auto;
    padding: 0px 12px;
    margin: 0;
  }
}
.selection-edit-main {
  .selection-edit-modal {
    .ant-row {
      .ant-radio-group {
        display: flex;
        align-items: center;
        justify-content: start;
        label {
          display: flex;
          align-items: baseline;
          margin-right: 20px;
        }
      }
    }
  }
  .add-select-update {
    .ant-form-item-control-input-content {
      display: flex;
      .ant-btn {
        padding-right: 0px;
      }
    }
  }
}
.add-select-new {
  h4 {
    font-size: 17px;
  }
  .ant-form-item {
    margin-bottom: 20px;
  }
}
.edit-selection {
  width: 100%;
  form {
    display: flex;
    justify-content: space-between;
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-input {
      min-width: 240px;
    }
  }
}
/* width */
.column-tasks-area::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.column-tasks-area::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.column-tasks-area::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 33px;
}

/* Handle on hover */
.column-tasks-area::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.history-modal .file-history {
  max-height: 400px;
  overflow-y: scroll;
  padding-right: 10px;
}

.history-modal .ant-modal-body {
  padding-right: 12px;
}

/* width */
.history-modal .file-history::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.history-modal .file-history::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.history-modal .file-history::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 33px;
}

/* Handle on hover */
.history-modal .file-history::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.badge-dot-style {
  .ant-badge-status-dot {
    width: 14px;
    height: 14px;
    border: solid 1px solid #ffffff rgb(221, 221, 221);
  }
}

.new-label {
  color: #fff !important;
  padding: 0 7px !important;
  border: none;
}

.select-without-border {
  .ant-select-selector {
    border: 0 !important;
    background: transparent !important;
  }
}
////////////////////////      Inventory Selection Style       /////////////////////////////////
.ant-picker-dropdown {
  z-index: 10000;
}
.inventory-slection-assets {
  padding: 3px;
  .inventory-slection-asset {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0px 12px 18px;
    border-radius: 6px;
    margin-bottom: 15px;
    cursor: pointer;
    &:hover {
      .i-slection-name {
        .ant-checkbox-wrapper {
          color: #ffffff;
        }
      }
      .i-selecion-icon {
        padding-right: 15px;
        .anticon {
          color: #ffffff;
        }
      }
    }
    .i-slection-name {
      font-size: 16px;
    }
    .i-selecion-icon {
      padding-right: 15px;
      .anticon {
        color: #888888;
      }
    }
    .i-selecion-btn {
      .ant-btn {
        font-size: 16px;
        transition-duration: 0s !important;
        span {
          text-decoration: underline;
        }
        .anticon {
          border-bottom: 0px;
          svg {
            font-size: 14px;
          }
        }
      }
    }
  }
  .active {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0px 12px 18px;
    border-radius: 6px;
    border: 0px !important;
    .i-selecion-icon {
      .anticon {
        color: #ffffff;
      }
    }
    .i-slection-name {
      font-size: 16px;
      .ant-checkbox-wrapper {
        color: #ffffff;
        .ant-checkbox {
          .ant-checkbox-inner {
            border-color: #ffffff;
          }
        }
      }
    }
    .i-selecion-btn {
      .ant-btn {
        font-size: 16px;
        span {
          text-decoration: underline;
        }
        .anticon {
          border-bottom: 0px;
          svg {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.metadata-form-collapse {
  h3 {
    font-size: 16px !important;
    margin-bottom: 0 !important;
  }
}
.slection-asset-view {
  padding: 22px;
  border-radius: 6px;
  margin-bottom: 25px;
  h3 {
    font-size: 17px;
    margin-bottom: 20px;
  }
  .formal-license-view {
    .formal-license-row {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      &:last-child {
        margin: 0;
      }
      .formal-license-name {
        width: 50%;
        span {
          font-size: 14px;
          font-weight: 500;
        }
        p {
          font-size: 11px;
        }
      }
      .formal-license-data {
        width: 50%;
        span {
          font-size: 14px;
          border-radius: 6px;
        }
        .ant-tag {
          border-radius: 6px;
          padding: 0px 8px;
          font-size: 12px;
          margin-bottom: 5px;
        }
      }
    }
    .formal-license-btn {
      display: flex;
      .ant-btn-link {
        padding: 0;
        margin-right: 40px;
        font-size: 14px;
      }
      .add-selection-btn {
        span {
          text-decoration: none;
        }
      }
    }
  }
}
.license-edit-model {
  .formal-license-btn {
    .ant-btn {
      width: 100%;
      height: auto;
      padding: 8px 0;
    }
  }
  h3 {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .slection-asset-view {
    padding: 0;
    margin-bottom: 30px;
    .formal-license-row {
      border-bottom: 1px solid #c7c7c7;
      padding: 12px 30px;
      margin-bottom: 0px;
      &:last-child {
        border-bottom: 0px;
      }
      .formal-license-name {
        width: 55%;
      }
      .formal-license-data {
        width: 45%;
      }
    }
    .formal-license-data {
      .ant-input-password {
        // border: 0;
        background-color: transparent;
        // border-color: #c7c7c7;
      }
      .ant-input {
        // border: 0;
        background-color: transparent !important;
        // border-color: #c7c7c7;
      }
      .ant-picker {
        background: transparent;
        border: 0px;
        color: #292929;
        width: 100%;
        .ant-picker-suffix,
        .ant-picker-clear {
          display: flex;
        }
      }
      .ant-form-item {
        margin-bottom: 0px;
      }
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        background: transparent;
        // border: 0px;
      }
      .anticon {
        svg {
          font-size: 10px;
          vertical-align: top;
          margin-right: 5px;
        }
      }
      .ant-select-multiple .ant-select-selector {
        background: transparent;
        // border: 0px;
        .ant-select-selection-item {
          border-radius: 6px;
          border-color: #292929;
          height: 24px;
          display: flex;
          align-items: center;
          .ant-select-selection-item-content {
            font-size: 12px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}
.request-p-modal {
  h3 {
    margin-bottom: 5px;
  }
  h4 {
    font-size: 16px;
    margin-bottom: 25px;
  }
  h5 {
    font-size: 14px;
    color: #292929;
  }
  .slection-asset-view {
    background-color: transparent;
  }
  .r-p-bg {
    background-color: #f3f3f3;
    border-radius: 6px;
    margin-bottom: 20px;
  }
}
.r-p-btn {
  display: flex;
  .ant-btn {
    &:first-child {
      margin-right: 20px;
      border-color: #292929;
      color: #292929;
    }
  }
}
.vendor-selection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .vendor-name {
    h3 {
      font-size: 22px;
    }
  }
  .request-p-p {
    button {
      font-size: 14px;
      padding: 0;
      display: flex;
      align-items: center;
      svg {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
      .anticon {
        font-size: 14px;
        padding: 3px;
        border-radius: 100px;
        height: 20px;
        width: 20px;
      }
    }
  }
}
.vendor-selection {
  .inventory-slection-asset {
    box-shadow: none !important;
    border: 1px solid #888888;
    .ant-checkbox-wrapper {
      color: #292929;
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #ffffff;
      }
      .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #ff246e !important;
      }
      .ant-checkbox {
        margin-right: 12px;
        .ant-checkbox-inner {
          border-color: #292929;
          border-radius: 0;
        }
      }
    }
  }
}

.add-item-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 15px 0 57px;
  height: 48px;

  .anticon {
    font-size: 14px;
    padding: 3px;
    border-radius: 100px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-size: 12px;

    svg {
      margin-right: 0;
    }
  }

  &:hover {
    .anticon {
      transform: scale(1.2);
    }
  }
}
.negotiation-btns {
  .ant-radio-button-wrapper,
  .ant-radio-button-wrapper:first-child:last-child {
    border-radius: 6px;
  }
}
.agreement-btn {
  background-color: #cfcfcf;
  color: #ffffff;
  border: 0px;
  margin-right: 12px;
}
.agreement-btn:hover,
.agreement-btn:focus,
.agreement-btn:active {
  background-color: #979797 !important;
  color: #ffffff !important;
}
.contract-header {
  position: absolute;
  top: 0;
  width: calc(100% - 18px);
  left: 8px;
  padding: 8px 24px;
  h3 {
    margin: 0;
  }
  p {
    margin: 0;
    color: #292929;
    margin-bottom: 6px;
  }
  .contract-time {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .contract-asset-profile {
    p {
      margin-bottom: 0px;
    }
    display: flex;
    align-items: center;
    .ant-avatar {
      margin-right: 18px;
    }
  }
}
.licnse-contract-sec {
  margin: 0px -16px;
}
.upload-btn {
  input[type="file"] {
    background-color: transparent;
    border: 0px;
    border-radius: 6px;
  }
  .ant-form input[type="file"]:focus,
  .ant-form input[type="radio"]:focus,
  .ant-form input[type="checkbox"]:focus {
    outline: 0px auto -webkit-focus-ring-color;
  }
  button {
    border: 30px solid;
  }
}
.contract-upload {
  padding: 18px 24px;
  h2 {
    display: flex;
    align-items: center;
    margin-bottom: 1.7rem;
    .anticon {
      font-size: 16px;
      margin-right: 10px;
    }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  p {
    color: #292929;
    max-width: 340px;
  }
  .ant-upload {
    margin-bottom: 10px;
    .ant-btn {
      border-color: #292929;
      color: #292929;
      display: flex;
      align-items: center;
      svg {
        width: 12px;
        margin-right: 6px;
      }
      .anticon {
        color: #ff246e;
      }
    }
  }
  .upload-history {
    padding: 8px 0;
    font-size: 13px;
    color: pink;
  }
}
.formal-license-btn {
  .add-selection-btn {
    margin-bottom: 0;
  }
}
.License-assets-collapse {
  .formal-license-btn {
    .ant-btn-link span {
      text-decoration: none !important;
    }
    .ant-btn-link {
      margin-right: 30px !important;
    }
    .add-definition-btn {
      display: flex;
      align-items: center;
      svg {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
      .anticon {
        border-radius: 30px;
        width: 16px;
        height: 16px;
        font-size: 12px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .slection-asset-view {
    margin-bottom: 10px;
  }
  .ant-collapse-content-box {
    padding: 0px;
  }
  .ant-collapse-item-active {
    .ant-collapse-header {
      margin-bottom: 10px !important;
    }
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 16px !important;
    left: auto;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    margin-bottom: 0px;
    border-radius: 6px;
    border: 1px solid;
    padding: 8px 16px;
  }
  padding: 0px 24px;
  .ant-collapse > .ant-collapse-item {
    border: 0px;
    border-radius: 6px;
  }
  .ant-collapse {
    border: 0px;
    background-color: transparent;
  }
  .ant-collapse-item {
    margin-bottom: 15px;
  }
  .ant-collapse-content {
    border: 0px;
  }
}
.chat-header-butns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .ant-btn {
    border-radius: 30px;
    margin-right: 10px;
    border-color: #292929;
    color: #292929;
    svg {
      width: 14px;
      fill: #292929;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.call-r-regulation {
  h2 {
    font-size: 26px;
  }
  p {
    color: #292929;
    line-height: 16px;
  }
  .ant-btn {
    width: 100%;
    padding: 7px 0;
    height: auto;
    margin-top: 1rem;
  }
  ul {
    list-style: none;
    color: #292929;
    padding-left: 1rem;
    li {
      position: relative;
      &:before {
        content: "";
        width: 5px;
        height: 1px solid #ffffff;
        background-color: #292929;
        position: absolute;
        left: -15px;
        top: 11px;
      }
    }
  }
}
.chat-video-call {
  background-color: #292929;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: -8px;
  h3 {
    color: #888888;
    font-size: 22px;
    font-weight: 300;
    margin: 0;
  }
}
.video-call-btn {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 15px;
  z-index: 10000;
  left: 50%;
  margin-left: -57px;
  .ant-btn {
    border-radius: 30px;
    margin-right: 10px;
    border-color: #292929;
    color: #888888;
    background-color: #494949;
    position: relative;
    width: 40px;
    height: 40px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.chat-style {
  margin-top: 85px;
  .contract-header {
    padding: 13px 24px;
    margin: 0 -24px;
    position: absolute;
    top: 0;
    width: 100%;
    left: 16px;
  }
  .chat-searchbar {
    padding: 6px;
    border-radius: 30px;
    margin-bottom: 10px;
    .ant-btn {
      padding: 0;
      border-radius: 100%;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-input {
      background-color: transparent;
      border: 0px;
      padding: 5px 0px 4px 4px;
    }
    .ant-input:hover,
    .ant-input:focus {
      border: 0px;
    }
    .attached-btn {
      font-size: 16px;
      margin-right: 5px;
    }
  }
}
.file-history {
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  h3 {
    margin-bottom: 2px;
    font-size: 14px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #888888;
      color: #888888;
      margin-bottom: 10px;
      border-radius: 6px;
      padding: 6px 10px;
      .file-history-name {
        display: flex;
        align-items: center;
        .anticon svg {
          font-size: 16px;
          margin-right: 10px;
        }
      }
      .file-history-download {
        button {
          padding: 0;
          margin-right: 5px;
          .anticon svg {
            font-size: 18px;
          }
        }
      }
    }
  }
}

//////////////////////////   Inventory Detial   ////////////////////////////////
.video-modal {
  background-color: black;
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    border-radius: 30px;
  }
  .video-error {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      color: white;
      align-items: center;
      font-size: 22px;
      margin-bottom: 30px;
    }
    h1 {
      color: white;
      align-items: center;
      font-size: 46px;
      margin-bottom: 10px;
    }
  }
}

.asset-detial-video {
  margin-bottom: 20px;
  h4 {
    font-size: 20px;
    margin-bottom: 6px;
  }
  img {
    width: 100%;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
  }
  .ant-carousel .slick-dots button {
    width: 12px;
    height: 12px;
    border-radius: 100%;
  }
  .slick-dots li.slick-active {
    width: auto;
  }
  .ant-carousel .slick-dots li {
    width: auto;
  }
  .asset-detail-screener {
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    cursor: pointer;
    img {
      position: relative;
    }
    svg {
      position: absolute;
      color: #ffffff;
      left: 50%;
      top: 50%;
      width: 25px;
      height: 25px;
      margin-top: -12px;
      margin-left: -12px;
    }
    .anticon {
      position: absolute;
      color: #ffffff;
      left: 50%;
      top: 50%;
      margin-left: -15px;
      font-size: 30px;
      margin-top: -15px;
    }
  }
  .inventory-grid {
    margin-bottom: 1rem;
    .inventory-grid-video {
      background: gray;
      padding: 90px;
      margin-bottom: 10px;
      cursor: pointer;
      border-radius: 10px;
    }
    h3 {
      margin-bottom: 6px;
    }
    .asset-grid-info {
      span {
        font-weight: 400;
        font-size: 15px;
      }
    }
  }
}
.asset-detail-text {
  display: flex;
  .lang-btn {
    margin: 12px 22px 0 10px;
    .ant-radio-button-wrapper {
      height: 28px;
      line-height: 26px;
      font-size: 12px;
      text-transform: uppercase;
      border-radius: 6px;
      border-color: #707070;
      color: #707070;
    }
  }
  .asset-detail-title {
    .asset-d-title {
      display: flex;
      align-items: center;
      h3 {
        font-size: 30px;
        margin: 0 25px 0 0;
      }
      p {
        margin: 0;
        color: #292929;
        font-size: 16px;
        font-weight: 500;
        .anticon {
          color: #ff246e;
          margin: 0 5px;
          font-weight: 100;
        }
      }
    }
    .duration {
      color: #888888;
      font-size: 16px;
      font-weight: 500;
      .border-right {
        border-right: 2px solid;
        padding-right: 15px;
      }
      span {
        font-size: 18px;
        color: #ff336a;
        margin-right: 15px;
        font-weight: 700;
      }
    }
    .asset-d-tags.mb5 {
      margin-bottom: 8px !important;
    }
    .asset-d-tags {
      > span {
        color: #292929;
        width: 90px;
        display: inline-block;
      }
      margin-bottom: 20px;
      .ant-tag {
        color: #292929;
        background-color: transparent;
        border-color: #292929;
        border-radius: 4px;
        display: inline;
        padding: 2px 14px;
        margin-right: 5px;
      }
    }
  }
}
.asset-d-g-list {
  display: flex;
  margin-bottom: 20px;
  .ant-btn {
    padding: 0;
    height: auto;
    color: #ff336a;
  }
  h4 {
    font-size: 17px;
    color: #292929;
  }
  p {
    font-size: 14px;
    color: #292929;
    margin-bottom: 5px;
  }
  ol {
    padding-left: 15px;
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      color: #292929;
    }
  }
}

/////////////////////////     Catalogues Style     //////////////////////////////////////////
.catalogues-banner {
  position: relative;
  .catalogue-bg-img {
    width: 100%;
    border-radius: 6px;
  }
  .catalogues-b-text {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 30px;
    text-transform: capitalize;
    img {
      margin-bottom: 1rem;
    }
    h2 {
      color: #fff;
      margin: 0;
      font-size: 28px;
      line-height: 36px;
    }
    .ant-btn-primary {
      margin-top: 2rem;
      font-size: 12px;
      padding: 10px 34px;
      height: auto;
    }
  }
}
.catalogue-b-grid {
  position: relative;
  .label-tag {
    svg {
      width: 132px;
      position: absolute;
      left: 7px;
      top: 1px;
    }
    img {
      max-width: 155px;
      left: 4px !important;
    }
    span {
      top: 3px !important;
      width: 100%;
      min-width: 150px;
      height: 100%;
    }
  }
  .catalogues-b-text {
    .mw50 {
      margin-bottom: 1rem;
      max-width: 55px;
    }
    h2 {
      color: #fff;
      margin: 0;
      font-size: 24px;
      line-height: 30px;
      font-weight: 400;
    }
    .ant-btn-secondary {
      margin-top: 1.5rem;
      font-size: 12px;
      padding: 6px 26px;
      height: auto;
      background-color: transparent;
      border: 1px solid #ffffff;
      color: #ffffff;
      &:hover {
        border-color: #ff246e;
        color: #ff246e;

      }
    }
  }
}
.label-tag {
  position: absolute;
  top: 15px;
  left: -18px;
  z-index: 1;
  svg {
    width: 100%;
    min-width: 120px;
    left: 2px;
    position: absolute;
    top: 1px;
  }
  img {
    position: relative;
  }
  span {
    position: absolute;
    left: 18px;
    top: 2px !important;
    color: #ffffff;
    font-size: 14px;
    width: 100%;
    min-width: 120px;
  }
}
.catalogues-by-logo {
  position: relative;
  // box-shadow: 0px 0px 4px 0px #d6d6d6;
  box-shadow: -1px 2px 6px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: -1px 2px 6px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -1px 2px 6px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  // min-height: 130px;
  // max-height: 130px;
  overflow: hidden;
  .label-tag {
    position: absolute;
    top: 6px;
    left: -13px;
    img {
      max-width: 130px;
    }
    span {
      position: absolute;
      left: 17px;
      top: 2px;
      color: #ffffff;
      font-size: 13px;
    }
  }
  img {
    max-width: auto;
    display: block;
    width: 100%;
    height: 100px;
    // height: auto;
    padding: 0.8vw;
    object-fit: contain;
  }
}
.member-profile-text {
  img {
    margin-bottom: 1.5rem;
    max-width: 220px;
  }
  h2 {
    font-size: 24px;
    color: #292929;
  }
  p {
    color: #292929;
    margin-bottom: 2rem;
    font-size: 16px;
  }
  .total-assets {
    h3 {
      font-size: 26px;
      margin: 0;
      line-height: 32px;
    }
    p {
      color: #292929;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
.member-profile-img {
  img {
    width: 100%;
    border-radius: 6px;
    .ant-skeleton-element {
      .ant-skeleton-image {
        width: 100%;
      }
    }
  }
  .ant-image-mask {
    border-radius: 6px;
  }
}
.member-profile-img .ant-skeleton-element {
  float: right;
  .ant-skeleton-image {
    width: 400px;
    height: 240px;
  }
}

.catalogues-grid {
  button {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    img {
      width: 100%;
      border-radius: 6px;
    }
  }
  h4 {
    margin: 0;
    font-size: 14px;
  }
}
.s-p-icon {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  svg {
    width: 14px;
    margin-right: 8px;
  }
}
p.add-selection-btn {
  font-size: 12px;
  button {
    color: #ff246e;
    font-size: 12px;
    width: auto;
    margin: 0;
    display: flex;
    align-items: center;
    &:hover,
    &:focus {
      color: #ff246e;
    }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }
  .anticon {
    background: transparent linear-gradient(270deg, #ff5e5e 0%, #ff246e 100%) 0%
    0% no-repeat padding-box;
    color: #ffffff;
    border-radius: 100px;
    height: 16px;
    width: 16px;
    transition: 0.3s;
    font-size: 12px;
    text-align: center;
    line-height: 18px;
  }
}
.column-10 .ant-col-3 {
  max-width: 10%;
}
.premium-profile-img {
  border-radius: 6px;
  box-shadow: 0px 0px 4px 3px whitesmoke;
  text-align: center;
  padding: 1rem 0;
}
.catalogues-p-grid {
  display: flex;
  button {
    width: auto;
    height: auto;
    margin-right: 1.5rem;
    img {
      min-width: 200px;
      max-width: 200px;
    }
  }
}
.catalogues-p-info {
  padding-right: 1rem;

  h4 {
    font-size: 22px;
    color: #292929;
    margin-bottom: 10px;
    line-height: 24px;
  }
  p {
    color: #292929;
    margin-bottom: 10px;
    font-weight: 500;
    &:last-child {
      margin: 0;
    }
  }
}
.m-cata {
  span {
    font-weight: 700;
  }
}
.duration {
  color: #888888 !important;
  span {
    color: #ff246e;
    font-size: 15px;
    margin-right: 1rem;
    font-weight: 500;
  }
}
.p-cata-grid {
  margin-bottom: 2rem;
  h2 {
    font-size: 26px;
    font-weight: 500px;
  }
  .highlight-mb {
    margin-bottom: 1vh;
  }
  span {
    font-size: 12px;
    color: #292929;
  }
  .catalogues-b-text {
    padding-left: 1.5rem;
    h2 {
      font-size: 22px;
      line-height: 26px;
    }
    .ant-btn-secondary {
      padding: 5px 12px;
      margin-top: 1rem;
    }
  }
}
.negotiation-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  span {
    color: #292929;
  }
}
.negotiation-modal .formal-license-data {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}
.negotiation-close {
  float: right;
}
.video-call-bg {
  position: relative;

  img {
    width: 100%;
  }
  .video-call-btn {
    .ant-btn {
      background-color: #ffffff;
      color: #292929;
      &:nth-child(2) {
        background-color: #ff3e3e;
        color: #ffffff;
        border-color: #ff3e3e;
      }
    }
  }
}
.call-progress {
  color: #292929;
  font-weight: 500;
  margin-right: 1.5rem;
}
.add-new-selection {
  margin: 0;
  button {
    border: 1px solid #292929;
    color: #292929 !important;
    padding: 7px 18px;
    height: auto;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
}
.breadcrumbs {
  .ant-btn {
    color: #ff246e;
    span {
      border-bottom: 1px solid #ff246e;
      line-height: 16px;
    }
  }
  .ant-link {
    color: #ff246e;
    span {
      border-bottom: 1px solid #ff246e;
      line-height: 16px;
    }
  }
}
.s-col-actions {
  margin-right: 10px;
  .ant-btn {
    color: #ff246e;
    span {
      border-bottom: 1px solid #ff246e;
      line-height: 16px;
    }
  }
}
.c-col-panel {
  background-color: #f3f3f3;
  padding: 20px;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  .License-assets-collapse .ant-collapse-item-active .ant-collapse-header {
    background: transparent linear-gradient(270deg, #ff5e5e 0%, #ff246e 100%) 0%
    0% no-repeat padding-box;
    border: 0px solid #ff246e !important;
    line-height: 32px;
    color: #ffffff;
  }
  .c-col-img {
    img {
      max-width: 145px;
      margin-right: 20px;
    }
  }
  .catalogues-p-info p {
    display: flex;
    font-size: 16px;
    span {
      min-width: 200px;
    }
  }
  .duration {
    display: inherit !important;
  }
}
.c-col-main {
  .ant-collapse-header {
    border: 0px !important;
    // box-shadow: 0px 0px 3px 0px #c7c7c7;
    border: 1px solid #888888 !important;
    line-height: 32px !important;
  }
}
.c-col-main {
  .ant-collapse-item-active {
    .ant-btn {
      color: #ffffff !important;
      span {
        border-bottom: 1px solid #ffffff;
      }
    }
  }
}

.radio-btn-selection {
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: transparent;
    color: #ff246e;
    border: 0px;
    font-weight: 500;
    .anticon {
      padding-left: 15px;
      color: #aeaeae;
    }
  }
  .ant-radio-button-wrapper:hover {
    position: relative;
    color: #ff246e;
  }
  .anticon {
    padding-left: 15px;
    color: #aeaeae;
  }
  .ant-radio-button-wrapper:first-child,
  .ant-radio-button-wrapper:not(:first-child)::before,
  .ant-radio-button-wrapper {
    border: 0px;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0;
  }
}
.radio-btn-breadcrums {
  display: flex;
  align-self: center;
  .select-asset {
    color: #aeaeae;
    line-height: 30px;
    .anticon {
      padding-left: 15px;
      padding-right: 5px;
    }
  }
  .ant-radio-button-wrapper {
    &:last-child {
      padding-right: 0px;
    }
  }
}
.add-asset-box {
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  min-height: 140px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  margin-bottom: 30px;
  &:hover {
    border-color: #ff246e;
  }
  .add-asset-icon {
    svg {
      height: 40px;
      margin-bottom: 12px;
    }
  }
  div {
    text-align: center;
    min-height: 50px;
  }
  img {
    margin-bottom: 1rem;
  }
  p {
    color: #808080;
    font-size: 15px;
    margin: 0;
  }
}
.add-asset-box-btn {
  width: 100%;
  border-color: #686868;
  color: #686868;
  height: auto;
  padding: 8px 0;
}
.no-asset-box {
  border: 1px solid #989898;
  border-radius: 6px;
  margin-bottom: 15px;
  padding: 15px 20px 20px;
  position: relative;
  width: 100%;
  p {
    color: #292929 !important;
    margin-bottom: 6px;
    font-size: 14px;
  }
  h2 {
    margin: 0;
  }
  .duration {
    color: #292929 !important;
    font-weight: 700;
    font-size: 14px;
    span {
      margin-left: 2rem;
    }
  }
  .asset-detail {
    position: absolute;
    right: 6px;
    bottom: 12px;
    .ant-btn-link {
      color: #ff246e;
    }
  }
}
.no-asset-header {
  h2 {
    font-size: 24px;
    margin-bottom: 10px;
    padding-left: 10px;
  }
  h3 {
    font-size: 18px;
    margin-bottom: 20px;
    padding-left: 8px;
    display: flex;
    align-items: center;
    svg {
      height: 16px;
      width: 18px;
      margin-right: 10px;
    }
  }
}
.no-asset-box-scroll {
  max-height: 470px;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  margin-bottom: 20px;
}
.no-asset-btns {
  width: 100%;
  display: flex;
  .ant-btn {
    width: 50%;
    margin: 0 5px;
    padding: 6px 0;
    height: auto;
  }
  Button.ant-btn-default {
    color: #292929 !important;
    border: 1px solid #292929 !important;
  }
}
.header-help {
  margin: 0 2rem;
  .anticon {
    background-color: #000;
    color: #ffffff;
    border-radius: 30px;
    font-size: 14px !important;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    display: flex;
    width: 22px;
    height: 22px;
  }
}
.header-profile {
  min-width: 250px;
}
.help-text {
  p {
    color: #292929;
    margin-bottom: 20px;
  }
}
.menu-double-title {
  margin-bottom: 5px;
  .ant-menu-submenu-title {
    .double-title {
      line-height: 16px;
      position: absolute;
      top: 11px;
      span {
        display: block;
        color: #bfbfbf;
        font-size: 12px;
      }
    }
  }
}
.buyer-slot {
  position: relative;
  h4 {
    margin-bottom: 2px;
  }
  .can-send {
    color: #4d4d4d;
    margin-bottom: 20px;
  }
  .buyer-slot-main {
    margin-bottom: 15px;
    .add-buyer-btn {
      position: absolute;
      right: 0;
      top: -5px;
    }
    .negotiation-close {
      position: absolute;
      right: 12px;
      font-size: 18px;
      color: #969696;
    }
    .buyer-single-row:first-child {
      border-radius: 6px 6px 0 0;
    }

    .buyer-single-row {
      position: relative;
      display: flex;
      align-items: center;
      background-color: #ebebeb;
      border-bottom: 1px solid #d6d6d6;
      padding: 10px 20px;
      .buyer-single-name {
        width: 50%;
        span {
          color: #292929;
          font-weight: 500;
        }
      }
      .buyer-single-select {
        width: 50%;
        .ant-form-item {
          margin: 0;
          width: auto;
          .ant-select {
            width: 90%;
            .ant-select-arrow {
              color: #292929;
            }
          }
          .ant-picker {
            background-color: #ebebeb;
            margin-right: 34px;
          }
          .ant-select-selector {
            background-color: transparent;
            width: auto;
            border: 0px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
.buyer-single-row.buyer-list-row:last-child {
  border-bottom: 0px;
  border-radius: 0 0 6px 6px !important;
}
.asset-action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-btn {
    width: 50%;
  }
}
.wallet-search {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  .ant-input-affix-wrapper {
    max-width: 400px;
    margin-bottom: 5px;
  }
}
.selection-header-btn {
  .ant-btn {
    border-color: #292929;
    color: #292929;
    margin-right: 10px;
    &:last-child {
      margin: 0;
    }
  }

  .ant-btn-default:focus {
    color: #292929;
    border-color: #292929;
  }
}
.more-btn {
  color: #ff246e;

  padding: 0;
  border-radius: 0;
  font-size: 12px;
  line-height: 14px;
  span {
    border-bottom: 1px solid #ff246e;
  }
  &:hover,
  &:focus,
  &:active {
    color: #292929;
  }
}
.footer-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .copy-rights {
    display: flex;
    .co-funded {
      display: flex;
      margin-right: 4rem;
      align-items: center;
      img {
        max-width: 65px;
        max-height: 34px;
      }
      .co-funded-text {
        text-align: right;
        padding-right: 15px;
        p {
          margin: 0px;
          color: #4b426f;
          font-weight: 500;
          line-height: 11px;
          font-size: 10px;
          img {
            max-width: 26px;
            vertical-align: baseline;
          }
        }
      }
    }
    .red-box {
      background-color: #ff246e;
      display: flex;
      align-items: flex-start;
      padding: 5px;
      text-align: right;
      min-height: 50px;
      p {
        margin: 0px;
        color: #ffffff;
        font-weight: 400;
        line-height: 10px;
        font-size: 10px;
      }
    }
  }
  .imz-footer {
    img {
      max-width: 90px;
      margin-bottom: 2px;
    }
    p {
      margin: 0px;
      color: #292929;
      font-weight: 500;
      line-height: 8px;
      font-size: 8px;
    }
  }
}
.expor-icons-height {
  height: 18px;
}
.send-msg-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
  }
}
.sync-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  .anticon {
    font-size: 20px;
    color: #888;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
    svg {
      fill: #888;
    }
  }
}
.view-cancel-btn {
  display: flex;
  .ant-btn {
    border-radius: 6px;
  }
}
.update-asset {
  .ant-btn-primary {
    border: 0px;
    color: #ffffff !important;
  }
}
.update-asset-btn {
  position: fixed;
  z-index: 999;
  right: 24px;
  top: 80px;
  background: 000;
  margin: 0;
  .ant-btn-primary {
    padding: 8px 16px;
    height: auto;
  }
  .ant-btn-primary[disabled] {
    background-color: #d1d1d1;
    color: #ffffff;
  }
}
.metadata-switch {
  padding-bottom: 1rem;
  width: 100%;

  .ant-affix {
    background: white;
    padding: 40px 0;
  }
}
.metadata-tab-active {
  .tab-error-sec {
    .anticon {
      color: #ffffff !important;
    }
  }
}
.error-f-tab {
  .anticon-down {
    top: 17px !important;
  }
}
.metadata-tab-title {
  border: 1px solid #888888;
  border-radius: 6px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;
  h3 {
    color: #292929;
    margin: 0;
  }
  .anticon {
    color: #888888;
    position: absolute;
    right: 25px;
    top: 14px;
  }
  .tab-error-sec {
    .anticon {
      color: #ff246e;
    }
  }
  .tabs-check-box {
    .ant-checkbox-inner {
      border: 1px solid #ff246e;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #fff;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      position: absolute;
      display: table;
      border: 2px solid #ff246e;
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: " ";
    }
  }
}
.hide-helper-text {
  display: none;
}
.metadata-tab-body_hide {
  height: 0;
  opacity: 0;
  visibility: hidden;
  display: none;
}
.metadata-tab-active {
  background: transparent linear-gradient(270deg, #ff5e5e 0%, #ff246e 100%) 0%
  0% no-repeat padding-box;
  border: 0px;
  h3 {
    color: #ffffff;
  }
  .anticon {
    color: #ffffff;
  }
}
.metadata-tab-body {
  position: relative;
  margin-bottom: 0;

  .ant-select-selection-placeholder {
    opacity: 1;
  }
  .ant-select-arrow {
    color: #ff246e;
  }
  .main-remove-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    cursor: pointer;
  }
  .ant-input {
    background-color: transparent;
    // border: 0px;
  }
  ::placeholder {
    color: rgba(41, 41, 41, 0.5);
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    // border: 0px;
    color: rgba(255, 45, 108, 1);
  }
  .ant-select-multiple .ant-select-selector {
    background-color: transparent;
    // border: 0px;
    color: #ff246e;
  }
  .ant-picker {
    background-color: transparent;
    // border: 0px;
    border-radius: 6px;
  }
  .ant-btn-dashed {
    background-color: transparent;
    color: #ff246e;
  }
  .ant-btn-link {
    color: #ff246e;
  }
  .ant-col {
    border-bottom: 1px solid #c7c7c7;
  }
  .ant-col:last-child {
    border: 0px;
  }
  .ant-row {
    margin-bottom: 10px;
    background-color: #ebebeb;
    border-radius: 6px;
  }
  .ant-input-number {
    background-color: transparent;
    // border: 0px;
    border-radius: 6px;
    .ant-input-number-handler-wrap {
      border-radius: 0 6px 6px 0;
    }
  }
}
.metadata-field-box {
  padding: 0 8px;
}
.metadata-box-single {
  border: 0;
}
.metadata-field-row {
  display: flex;
  padding: 10px 20px;
  align-items: center;

  .metadata-field-name {
    h3 {
      margin: 0;
      font-size: 15px;
    }
    width: 40%;
  }
  .metadata-filed {
    .upload-btn {
      padding-left: 10px;
    }
    width: 60%;
    .ant-btn {
      width: auto;
      color: #ff246e;
      display: flex;
      align-items: center;
      border: 1px solid #c7c7c7;
      background: transparent;
      &:hover {
        border: 1px solid #FF246E;
      }
      .anticon {
        font-size: 12px;
        color: #ff246e;
      }
    }

    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-radio-group {
      padding-left: 12px;
    }
  }
}
.general-tab > .ant-row {
  margin: 0;
  border-radius: 0px;
  border-bottom: 1px solid #c7c7c7;
}
.general-tab > .ant-row:last-child {
  border: 0px;
}
.general-tab .ant-row:first-child {
  border-radius: 6px 6px 0 0;
}
.general-tab .ant-row:last-child {
  border-radius: 0px 0px 6px 6px;
}
.heper-text p {
  font-size: 14px;
  color: #292929;
  margin-bottom: 10px;
}
.catalogue-filter {
  .my-market-inventory {
    border: 0px;
    padding: 25px 24px 0px;
    margin-bottom: 10px;
    .inventory-search-bar {
      margin: 0;
    }
  }
}
.alphabet-filter {
  margin-bottom: 30px;
  .ant-radio-button-wrapper {
    border: 0px;
    padding: 0 6px;
    font-size: 16px;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  .ant-radio-button-wrapper:hover {
    color: #ff246e;
  }
}
.remove-btn {
  display: flex;
  align-items: center;
  background-color: #ff3436;
  font-size: 12px;
  padding: 4px 10px;
  border-radius: 6px;
  span {
    color: #ffffff;
  }
}
.remove-seprate-btn {
  max-width: 100px;
  margin: 0px -7px 15px 0px;
  .anticon {
    margin-left: 4px !important;
  }
  &:hover {
    background-color: red;
  }
}

.metadata-tab-body ::placeholder {
  color: rgba(41, 41, 41, 0.5) !important;
}
.update-asset-modal {
  h2 {
    font-size: 22px;
  }
  p {
    color: #292929;
    margin-bottom: 17px;
  }
  .ant-btn {
    width: 100%;
    margin-top: 1rem;
  }
}
.c-d-title {
  display: flex;
  align-items: center;
  h4 {
    margin-right: 0.8rem;
  }
  span {
    color: #292929;
    font-weight: 500;
  }
  .anticon {
    color: #ff246e;
    margin: 0 1rem;
  }
}
.iscc-code {
  padding-left: 11px;
}
.remove-circle {
  position: absolute;
  right: 10px;
  color: #ff246e;
}
.remove-circle-tow {
  position: absolute;
  right: 30px;
  color: #ff246e;
}
.remove-circle-three {
  position: absolute;
  right: -14px;
  color: #ff246e;
}
.remove-gray-icon {
  padding: 18px 15px;
  float: right;
}
.add-more-btn {
  margin-right: -6px;
}
.code-generator {
  .ant-btn-loading-icon {
    width: auto;
    .anticon {
      color: #ffffff !important;
    }
  }
}
.editor-gyer {
  margin-left: 10px;
}
.show-half-summary {
  height: 44px;
  overflow: hidden;
}
.show-full-summary {
  height: auto;
}
.hide-sec {
  display: none;
}
.organization-profile {
  max-width: 500px;
  width: 500px;
  min-width: 500px;
  margin: auto;
  display: flex;
  height: 100%;
  align-items: center;
  .slection-asset-view {
    background-color: #ebebeb;
  }
  .formal-license-row {
    padding: 12px 20px !important;
    align-items: center;
  }
  .edit-profile-textarea {
    align-items: flex-start !important;
  }
}

/* Let's get this party started */
.edit-profile-textarea .full-width {
  max-height: 576px;
  overflow: auto;
  // padding: 0px 2px;
  margin-bottom: 20px;
}
.edit-profile-textarea .full-width::-webkit-scrollbar {
  width: 4px;
  margin-left: 10px;
}

/* Track */
.edit-profile-textarea .full-width::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
.edit-profile-textarea .full-width::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(240, 242, 245, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.edit-profile-textarea .full-width::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(203, 203, 204, 0.6);
}

.organization-p-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  h3 {
    font-size: 22px;
    margin-bottom: 5px;
  }
  .ant-btn {
    padding: 0;
    color: #ff4d4f;
  }
}
.formal-license-data .ant-upload.ant-upload-select-picture-card {
  background-color: transparent !important;
  width: 70%;
  margin: auto;
  margin-left: 10px;
}

.formal-license-data .ant-upload {
  padding: 0px !important;
  height: 80px !important;
}

.upload-hero-image .ant-upload-list-picture-card-container {
  width: 100% !important;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  width: 100%;
  margin: 5px 0 0px 10px;
  border: 0px;
  padding: 0;
}
.upload-hero-image .ant-upload {
  height: auto !important;
  border: 0px;
  font-size: 12px !important;
  color: #ff4d4f !important;
  width: auto !important;
}

.upload-hero-image {
  .ant-upload-list-picture-card
    .ant-upload-list-item
    .ant-upload-list-item-actions {
    display: flex;
    align-items: center;
    line-height: 2px;

    .anticon svg {
      font-size: 17px;
    }
  }
}
.profile-menu {
  padding: 0;
  border-radius: 7px;
  .ant-dropdown-menu-item {
    &:first-child {
      border-bottom: 1px solid #ebebeb;
      border-radius: 7px 7px 0 0;
    }
    &:last-child {
      border-radius: 0 0 7px 7px;
    }
    p {
      margin: 0;
      padding: 7px;
      color: #292929;
      display: flex;
      align-items: center;
      svg {
        width: 12px;
        margin-right: 15px;
      }
      .anticon {
        color: #292929;
        margin-right: 12px;
        svg {
          margin-right: 4px;
        }
      }
    }
  }
}
.profile-username {
  padding: 16px 20px;
  .formal-license-name {
    span {
      font-size: 14px !important;
    }
  }
  .formal-license-row {
    margin-bottom: 0px !important;
    .formal-license-data {
      background-color: transparent;
      .ant-input {
        background-color: transparent;
        border: 0;
        color: #888888;
      }
    }
  }
}
.edit-profile-btn {
  .ant-btn-primary {
    width: 100%;
    height: 40px;
  }
}
.user-profile-picture {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  .ant-avatar-circle {
    min-width: 130px;
    min-height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
  }
  .ant-form-item-control-input-content {
    position: relative;
    .upload-profile-btn {
      position: absolute;
      bottom: 0px;
      right: 15px;
      .ant-upload-list {
        display: none;
      }
      .ant-btn {
        border-radius: 30px;
        background: transparent
        linear-gradient(270deg, #ff5e5e 0%, #ff246e 100%) 0% 0% no-repeat
        padding-box;

        border: 0px;
        .anticon {
          color: #ffffff;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
.user-info-text {
  text-align: center;
  margin-bottom: 30px;
  h2 {
    font-size: 22px;
    margin-bottom: 0;
    text-transform: capitalize;
  }
}
.change-password {
  text-align: center;
  .ant-btn-link {
    color: #ff246e;
    span {
      text-decoration: underline;
    }
  }
}
.password-form {
  .formal-license-row {
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 10px !important;
    padding: 0 20px 10px 20px;
    &:first-child {
      padding: 10px 20px;
    }
    &:last-child {
      margin: 0;
      border: 0px;
      padding-bottom: 10px;
    }
  }
  padding: 0;
  .ant-form-item {
    margin: 0;
  }
  .ant-input-affix-wrapper {
    background-color: transparent;
    border: 0;
    .ant-input {
      background-color: transparent;
      color: #888888;
    }
  }
}
.preview-tabslist {
  width: 100%;
  max-height: 191px;
  overflow: auto;
  padding-right: 12px;
  padding-left: 10px;
  font-weight: 500;
  p {
    color: #292929;
  }
  .inventory-slection-asset {
    padding: 9px 0px 10px 18px;
    margin-bottom: 10px;
  }
  .inventory-slection-assets {
    padding: 0;
  }
}
/**********         Custom Scroll Style         *************/
/* Let's get this party started */
.preview-tabslist::-webkit-scrollbar {
  width: 4px;
  margin-left: 10px;
}
.ant-card-body:hover .preview-tabslist::-webkit-scrollbar {
  display: block;
}

/* Track */
.preview-tabslist::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
.preview-tabslist::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(240, 242, 245, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.preview-tabslist::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(203, 203, 204, 0.6);
}
.preview-modal-info {
  width: 100%;
  margin-bottom: 15px;
  padding: 0 17px 0 10px;
  h4 {
    margin-bottom: 0px;
    font-weight: 700;
  }
  .preview-modal-sec {
    display: flex;
    font-weight: 500;
    .approvals {
      display: flex;
      align-items: center;
      margin-right: 4rem;
      h4 {
        margin-right: 15px;
      }
    }
  }
}
.preview-modal-btns {
  .ant-btn {
    margin: 0 15px 0 0;
  }
}
.nego-and-pro-btns {
  .ant-btn {
    width: 32% !important;
  }
}
.modal-trading {
  top: 64px;
  margin: 0;
  padding: 0;
  max-width: 100%;
  border-radius: 0;
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-content {
    background-color: rgba(255, 255, 255, 0.98);
    border-radius: 0;
    height: 100vh;
    overflow: overlay;
    // .ant-modal-body {
    //   height: 100vh;
    // }
  }
}
.modal-trading .ant-modal-content::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.modal-trading .ant-modal-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
.modal-trading .ant-modal-content::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(240, 242, 245, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.modal-trading .ant-modal-content::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(203, 203, 204, 0.6);
}
.trading-task-detail {
  width: 100%;
  margin: 0px auto;
  .back-button {
    display: flex;
    align-items: center;
    color: #ff246e;
    font-weight: 500;
    padding: 0;
    font-size: 18px;
    margin-bottom: 1.5rem;
    margin-left: 10px;
    .anticon {
      border: 1px solid #ff246e;
      border-radius: 30px;
      width: 15px;
      height: 15px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 9px;
      margin-right: 8px;
    }
  }
}
.trading-task-detail-tabs {
  padding: 0;
  margin-bottom: 0px;
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 6px 16px;
  }
  .ant-collapse-header {
    box-shadow: none;
    border: 1px solid #888888 !important;
  }
  .no-asset-header {
    h3 {
      padding: 0;
      margin-bottom: 8px;
    }
    p {
      color: #292929;
    }
  }
  .ant-checkbox-inner {
    border-color: #888888;
  }
  .ant-checkbox-wrapper {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #ffffff;
      border-color: #888888;
      &::after {
        border: 2px solid #ff246e;
        border-top: 0;
        border-left: 0;
      }
    }
  }
  .ant-collapse-item-active {
    .ant-collapse-header {
      .ant-checkbox-wrapper {
        color: #ffffff;
        .ant-checkbox-checked .ant-checkbox-inner {
          border-color: #ffffff;
          background-color: #ffffff;
          &::after {
            border: 2px solid #ff246e;
            border-top: 0;
            border-left: 0;
          }
        }
      }
    }
  }
}
.available-permissions-list {
  margin-bottom: 25px;
  padding: 0;
  min-height: 190px;
  .ant-form-item-control {
    display: flex;
    flex-direction: column-reverse;
  }
  .ant-checkbox-group {
    .ant-checkbox-wrapper {
      display: flex;
      margin: 9px 0;
      .ant-checkbox-inner {
        width: 14px;
        height: 14px;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #fff;
        border-color: #fff;
        border: 1px solid #d9d9d9;
      }
      .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #ff246e;
        width: 5px;
        height: 8px;
      }
    }
  }
}
.role-management-list {
  .role-list-box {
    width: 100%;
    border: 1px solid #888888;
    border-radius: 6px;
    padding: 25px;
    margin-bottom: 25px;
    // min-height: 180px;
    display: flex;
    align-items: start;
    height: 100%;
    > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: start;
      justify-content: space-between;
    }
    h2 {
      font-size: 24px;
      margin-bottom: 10px;
      text-transform: capitalize;
    }
    h5 {
      margin: 0;
      font-size: 16px;
    }
    p {
      color: #292929;
      margin-bottom: 1rem;
    }
  }
  .role-list-btn {
    display: flex;
    align-items: center;
    .ant-btn {
      color: #ff246e;
      padding: 0;
      margin-right: 20px;
      span {
        text-decoration: underline;
      }
    }
  }
}

/**********         Custom Scroll Style         *************/
/* Let's get this party started */
.select-right-scroll {
  max-height: 576px;
  overflow: auto;
  padding: 0px 2px;
  margin-bottom: 20px;
}
.select-right-scroll::-webkit-scrollbar {
  width: 4px;
  margin-left: 10px;
  display: none;
}
.ant-card-body:hover .select-right-scroll::-webkit-scrollbar {
  display: block;
}

/* Track */
.select-right-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
.select-right-scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(240, 242, 245, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.select-right-scroll::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(203, 203, 204, 0.6);
}
.primary-link-btn {
  .ant-btn-primary {
    height: 40px;
    padding: 0 30px;
  }

  a {
    color: #ffffff;
    background: transparent linear-gradient(270deg, #ff5e5e 0%, #ff246e 100%) 0%
    0% no-repeat padding-box;
    border: none;
    padding: 9px 12px;
    border-radius: 6px;
    .anticon {
      margin-left: 50px;
    }
    :hover {
      color: #ffffff !important;
    }
  }
}
.select-right-filter {
  .inventory-bottom-filter {
    margin: 40px 0 0px;
  }
}
.request-proposal-header {
  border-bottom: 1px solid #292929;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0 10px;
  .anticon {
    border: 1px solid #ff246e;
    border-radius: 30px;
    font-size: 10px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h3 {
    font-size: 22px;
    margin: 0;
    padding: 0 8px;
  }
}
.request-proposal-header-active {
  border: 0px;
  .anticon {
    transform: rotate(180deg);
  }
}
.request-proposal-form {
  .ant-row {
    margin-bottom: 0px;
    background-color: transparent;
    border-radius: 0px;
    .ant-col {
      border-bottom: 0px solid #c7c7c7;
    }
  }
  .request-proposal-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ebebeb;
    border-radius: 6px;
    padding: 7px 14px;
    margin-bottom: 10px;
    .proposal-field-name {
      h3 {
        margin: 0;
        font-size: 14px;
      }
    }
    .request-proposal-field {
      min-width: 140px;
      .ant-row {
        margin-bottom: 0 !important;
      }
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        background-color: transparent;
        border: 0px;
        color: #292929;
      }
      .ant-select-arrow {
        color: #292929;
      }
    }
  }
}
.proposal-date-field::after {
  content: "";
  background-color: #c7c7c7;
  width: 1px;
  height: 25px;
  position: absolute;
  left: 45%;
  top: 34px;
}
.request-approvals-btns {
  padding: 5px 0 0;
  display: flex;
  align-items: flex-start;
  .ant-btn {
    display: block;
    /* margin-bottom: 15px; */
    /* padding: 7px 15px; */
    height: auto;
    /* min-width: 178px; */
    font-size: 0.65rem;
    border-radius: 0.15rem;
  }
  .req {
    background-color: transparent;
    color: #888888;
    border-color: #888888;
  }
  .apr {
    color: #292929;
    border-color: #292929;
    // margin-bottom: 3rem;
  }
}
.proceed-btns {
  display: flex;
  justify-content: flex-end;
  .ant-btn {
    display: block;
    margin-bottom: 15px;
    padding: 9px 15px;
    height: auto;
    color: #888888;
    // border-color: #888888;
    // border-width: 1.5px solid;
    border: 1px solid #888888;
    &:hover {
      background-color: #ff246e;
      color: #ffffff;
    }
    span {
      font-size: 16px;
      font-weight: 500;
    }
    .anticon {
      margin-left: 5rem;
    }
  }
}
.edit-btn {
  background: none !important;
  color: #ff246e !important;
}
.organization-profile-detail {
  height: 100%;
  display: flex;
  align-items: center;
  .member-profile-text {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .total-assets {
      p {
        margin-bottom: unset;
      }
    }
  }
}
.usre-management-action {
  .ant-space-item {
    &:first-child {
      min-width: 37px;
    }
  }
}

.logo-text-style {
  width: 300px;
  line-height: 20px;

  h1 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }

  h4 {
    font-weight: 500;
    opacity: 0.6;
    font-size: 12px;
    margin: 0;
    line-height: 16px;
  }
}

.delete-confirm-style {
  text-align: center;

  h3 {
    font-size: 24px;
    margin-bottom: 15px;
    line-height: 30px;
  }

  p {
    font-size: 14px;
    margin-bottom: 25px;
    color: #7b7b7b;
    padding: 0 15px;
  }
}
.invitation-party-text {
  p {
    color: #292929;
    margin-bottom: 25px;
  }
}
.help-search .ant-input-affix-wrapper {
  margin-bottom: 0px;
}
.duration-pink {
  color: #ff246e !important;
}
.metadata-tab-border {
  border-bottom: 1px solid #292929;

  .ant-collapse-arrow {
    display: none !important;
  }

  .i-selecion-btn button {
    padding-right: 40px;
  }
}
.app-header-trading-task {
  position: absolute;
  width: 100%;
  left: 0;
  top: -64px;
}
.tabe-editor-menu {
  min-width: 200px;
}
.cascading-filter {
  min-width: 100%;
  .ant-space {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 6px;
      border-color: #888888;
    }
  }
}
.cascading-btn {
  display: flex;
  .ant-form-item-control-input-content {
    margin-right: 20px;
    .ant-btn {
      padding: 4px 0px;
      height: auto;
      min-width: 120px;
      font-size: 13px;
    }
    .ant-btn-secondry {
      color: #292929;
      border-color: #292929;
    }
    .ant-btn-primary {
      padding: 5px 0px;
    }
  }
}
.cascading-columns {
  display: flex;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 16px;
  span {
    width: 49%;
    font-weight: 600;
    color: #000000;
  }
}
.cascading-short {
  font-weight: 500;
  color: #000000;
}
.cascading-modal-header {
  p {
    color: #292929;
  }
}
.evaluate-sec {
  .no-asset-header {
    margin-bottom: 0px;
    h3 {
      margin-bottom: 10px;
    }
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 25px;
    ::before {
      border-bottom: 2px solid #dbdbdb;
    }
  }
  .ant-tabs-nav-list {
    width: 100%;
    .ant-tabs-tab {
      width: 50%;
      margin-right: 0px;
      display: flex;
      justify-content: center;
      padding: 12px 0px;
      border-bottom: 2px solid #dbdbdb;
    }
    .ant-tabs-tab-btn {
      color: #292929;
    }
    .ant-tabs-tab-active {
      padding: 12px 0px;
      border-bottom: 2px solid #ff246e;
      z-index: 1;
      .ant-tabs-tab-btn {
        color: #ff246e;
        font-weight: 400;
      }
    }
  }
}
.evaluate-assets-modal {
  width: 100%;
  .no-asset-box-scroll {
    width: calc(100% - 8px);
  }
  .column-tasks-area {
    padding-right: 0px;
  }
  .no-asset-btns {
    padding: 0 3px;
  }
}
.permissions-modal {
  h2 {
    font-size: 25px;
    margin-bottom: 25px;
  }
  p {
    color: #292929;
    font-weight: 500;
  }
  .ant-form-item {
    margin-bottom: 10px;
    .ant-radio-group {
      .ant-radio-wrapper {
        font-weight: 500;
        color: #292929;
      }
    }
    .ant-input-number {
      color: #292929;
      padding: 3px 0px;
      min-width: 220px;
      border-radius: 6px;
      border-color: #888888;
      line-height: 31px;
      margin-bottom: 10px;
    }
  }
  .no-asset-btns {
    .ant-btn {
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.block-p-text {
  display: flex;
  align-items: center;
  color: #ff246e;
  margin-bottom: 20px;
  svg {
    color: #ff246e;
    margin-right: 8px;
  }
}
.header-filter-title {
  font-size: 12px;
  margin-bottom: 4px;
}
.saved-search-sec {
  margin-bottom: 15px;
}
.saved-search-btn {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  h4 {
    width: 100%;
  }
  .ant-btn-link {
    padding: 0;
    width: auto;
    font-size: 11px;
    color: #ff246e;
  }
}
.saved-search-filter {
  h2 {
    margin-bottom: 10px;
    font-size: 24px;
  }
}
.saved-search-dell {
  a {
    color: #ff246e;
    margin-right: 10px;
  }
}
.change-password-sec {
  .ant-form-item-children-icon {
    span {
      color: #ff3436 !important;
    }
  }
}
.asset-grid-info {
  display: flex;
  margin-bottom: 5px;
  h4 {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 20px;
    text-transform: capitalize;
  }
  span {
    font-size: 16px;
    line-height: 18px;
    text-transform: capitalize;
    margin-bottom: 0;
  }
}
.asset-grid-btn {
  .ant-btn {
    margin-right: 2rem;
    color: #ff6190;
    font-weight: 500;
    &:last-child {
      margin: 0;
    }
  }
}
.asset-grid-seprator {
  width: 100%;
  height: 2px;
  background-color: #ebebeb;
  margin: 20px 0 4px;
}
.edit-profile-username {
  span {
    color: #757575 !important;
  }
}
.inventory-grid-video {
  position: relative;
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 70px;
    height: 70px;
    margin-left: -35px;
    margin-top: -35px;
  }
}
.dot-badge {
  .ant-badge-dot {
    width: 8px;
    height: 8px;
    background: transparent linear-gradient(270deg, #ff5e5e 0%, #ff246e 100%) 0%
    0% no-repeat padding-box;

  }
}
.drop-dot-badge {
  .ant-badge-dot {
    width: 6px;
    height: 6px;
    background: transparent linear-gradient(270deg, #ff5e5e 0%, #ff246e 100%) 0%
    0% no-repeat padding-box;
    top: -3px;
    right: -3px;
  }
}
.user-status {
  text-transform: capitalize;
}
.task-add-text {
  color: #292929;
  margin-bottom: 25px;
}
.specific-roles {
  h3 {
    font-size: 26px;
  }
  .ant-tag {
    border-color: #292929;
    color: #292929;
    border-radius: 6px;
    background-color: transparent;
    margin-bottom: 8px;
  }
}
.specific-role-btn {
  color: #ff246e;
  padding: 0;
  &:hover,
  &:focus {
    color: #292929;
  }
}
.specific-lang-btn {
  width: 100%;
  line-height: 0;
  height: 10px;
}
.inventory-save-search {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.save-search {
  .ant-btn {
    color: #ff246e;
    padding: 0;
    span {
      text-decoration: underline;
      margin-left: 5px;
    }
  }
}
.save-search-quries .inventory-search-tabs {
  width: 100%;
  padding: 3px 0;
  margin: 0;
  span {
    padding: 0px 9px;
  }
}
.catalog-title {
  font-size: 24px;
}
.new-catalogues-grid {
  img {
    width: 100%;
    border-radius: 6px;
  }
  .new-catalogues-info {
    padding: 6px 0 0;
    h3 {
      margin: 0;
      font-size: 16px;
    }
    h4 {
      margin: 0;
      span {
        font-weight: 400;
      }
    }
  }
}
.catalouge-btns {
  .ant-btn {
    margin-right: 15px;
    height: auto;
    span {
      color: #ff246e;
      text-decoration: underline;
      font-size: 16px;
    }
  }
}
.header-btn-groups {
  a {
    margin-right: 10px;
  }
  .ant-btn {
    height: auto;
    padding: 8px 18px;
  }
  .ant-btn-default {
    border-color: #292929;
    color: #292929;
    padding: 7px 17px;
  }
}
.licence-agreement-sec {
  padding: 25px 24px;
  .ant-btn {
    color: #ff246e;
    padding: 0px;
  }
  div {
    margin-bottom: 8px;
    color: #292929;
    .ant-btn {
      border-color: #292929;
      color: #292929;
      height: auto;
      padding: 6px 10px;
      margin-right: 10px;
    }
  }
  h2 {
    font-size: 22px;
  }
  p {
    color: #292929;
    font-size: 15px;
    margin-bottom: 5px;
  }
  .agreement-url {
    color: #ff246e;
    display: flex;
    font-size: 15px;
  }
}
.licence-agreement-btn {
  padding: 25px 24px;
  .ant-btn {
    margin-right: 10px;
  }
}
.screener-upload-drag-icon {
  .ant-upload.ant-upload-drag {
    position: relative;
    width: 35%;
    height: 100%;
    text-align: center;
    background: #dedede !important;
    border: 0px dashed #d9d9d9;
    border-radius: 10px;
    cursor: pointer;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s;
    margin-left: 10px;
    .ant-upload {
      padding: 30px 40px;
    }
  }
  .ant-upload-drag-container {
    .add-asset-icon {
      height: 60px;
      width: 60px;
      margin: 10px auto;
    }
    .anticon {
      font-size: 22px;
      color: #888888;
      margin-bottom: 8px;
    }
    p {
      color: #888888 !important;
      line-height: 14px;
      font-size: 12px !important;
    }
  }
}
.add-new-trailer-btn {
  position: absolute;
  left: 17px;
  top: 35px;
}
.metadata-detault {
  padding: 6px 0 0;
}
.add-metadata-section {
  align-items: self-start;
  padding: 15px 20px;
}
.organization-logo-btn {
  .ant-upload {
    height: auto !important;
    .ant-btn {
      background-color: #dedede;
      padding: 0px;
      border-radius: 10px;
      width: 128px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0px;
      .anticon {
        svg {
          font-size: 20px !important;
          margin-right: 0px !important;
          color: #888888;
        }
      }
    }
  }
}
.hero-image-thumbnail {
  img {
    width: 100%;
    border-radius: 6px;
    margin-bottom: 10px;
  }
}
.a-m-gear-btn {
  .editor-gyer {
    margin-left: 0;
    margin-right: 10px;
  }
}
.set-permission-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: 1.9rem;
  .ant-btn {
    svg {
      width: 13px;
      fill: none;
      stroke: #ff246e;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 1.5px;
      margin-right: 8px;
    }
  }
}
.dead-line {
  .ant-picker-suffix {
    color: #292929;
  }
}
.trailer-modal {
  position: fixed;
  z-index: 1;
  width: 25%;
  border-radius: 6px;
  background: #fff;
  padding: 25px;
  box-shadow: 0px 0px 9px -1px #2d2d2d;
  display: none;
  left: 50%;
  top: 50%;
  margin-top: -244px;
  margin-left: -240px;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: red;
  }
  .formal-license-btn {
    .ant-btn-primary {
      color: #ffffff !important;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.trailer-modal-show {
  display: block;
}
.trailer-modal-background {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1111;
}
.close-btn-modal {
  position: absolute;
  right: 12px;
  top: 12px;

  .ant-btn {
    background-color: #ebebeb;
    border-radius: 30px;
    width: 24px !important;
    height: 25px;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    padding: 0 !important;
    span {
      color: #000;
    }
  }
}
.trailer-and-screener-sec {
  padding: 15px 0px 40px 20px;
}
.subtitles-lang {
  li {
    text-transform: uppercase;
  }
}
.metadata-filed {
  .ant-btn {
    .anticon {
      font-size: 14px !important;
      color: #ff246e;
    }
  }
  .ant-upload-list-picture-card-container {
    height: 100%;
    margin: 0 10px 0 0 !important;
    background-color: #dedede;
    border-radius: 6px;
    .ant-upload-list-item-actions {
      a {
        display: flex;
        align-items: center;
      }
    }
    .ant-upload-list-item {
      margin: 0;
      .ant-upload-list-item-info {
        .ant-upload-span {
          .ant-upload-list-item-thumbnail {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .ant-upload-list-item-name {
            display: none;
          }
        }
      }
      .ant-upload-list-item-info::before {
        border-radius: 6px;
      }
    }
  }
  .screener-upload-drag-icon {
    span {
      display: flex;
      .ant-upload {
        margin-right: 10px;
        .ant-upload-drag-container {
          .anticon {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}

.user-m-filter {
  span {
    margin-right: 3rem;
  }
}
.highlights-catalogue {
  padding: 6px 0 0;
  h3 {
    margin: 0;
  }
  h4 {
    margin: 0;
  }
}
.catalogue-back {
  width: 100%;
  margin-bottom: 1rem;
}
.wallet-modal-sec {
  h1 {
    font-size: 26px;
  }
  .wallet-modal {
    max-height: 270px;
    padding: 20px 0;
    overflow: auto;
    margin-bottom: 20px;
    background-color: #f3f3f3;
    border-radius: 6px;
    .ant-radio-button-wrapper-checked::before {
      content: "";
      width: 10px;
      height: 2px;
      border-radius: 50px;
      position: absolute;
      left: 22px;
      top: 13px;
      background-color: #ff246e !important;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: #ff246e;
      background: #eae8e8;
      border-radius: 6px;
    }
    .ant-radio-button-wrapper {
      &:last-child {
        margin: 0;
      }
      &:hover {
        color: #ff246e !important;
      }
      width: 100%;
      border: 0px;
      background: transparent;
      margin-bottom: 20px;
      padding: 0px 20px;
      span {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .anticon-wallet {
          margin-right: 10px;
        }
      }
    }
  }
}

/**********         Custom Scroll Style         *************/
/* Let's get this party started */
.wallet-modal::-webkit-scrollbar {
  width: 4px;
  margin-left: 10px;
}
/* Track */
.wallet-modal::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
.wallet-modal::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(240, 242, 245, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.wallet-modal::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(203, 203, 204, 0.6);
}
.promotional-banner {
  .ant-upload-list-picture-card-container {
    display: inline-block;
    width: 100%;
    height: 370px;
    margin: 0px;
    background-color: #ebebeb;
    border-radius: 10px;
    vertical-align: top;
    .ant-upload-list-item {
      margin: 0px !important;
      .ant-upload-list-item-actions {
        .anticon {
          font-size: 28px;
          margin: 0 20px;
        }
      }
    }
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 370px;
    margin-right: 0px;
    margin-bottom: 0px;
    text-align: center;
    vertical-align: top;
    background-color: #ebebeb;
    border: 0px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s;
    .promotional-text {
      display: grid;
      .anticon-plus {
        background-color: #dbdbdb;
        width: 100px;
        height: 100px;
        margin: auto;
        border-radius: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        font-size: 30px;
        justify-content: center;
        color: #888888;
      }
      .promotional-info {
        color: #292929;
        font-size: 17px;
        font-weight: 500;
      }
    }
  }
}
.antd-img-crop-modal {
  .ant-modal-header {
    border-bottom: 0px;
    .ant-modal-title {
      font-size: 26px;
      color: #292929;
    }
  }
  .ant-modal-footer {
    border-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-btn {
      width: 100%;
      color: #292929;
      border-color: #292929;
    }
    .ant-btn-primary {
      color: #ffffff;
      border: 0px;
    }
  }
}
.hightlight-sec {
  .ant-upload.ant-upload-select-picture-card {
    width: 490px;
    height: 270px;
  }
}
.highlight-title {
  margin-bottom: 20px;
  h2 {
    font-size: 24px;
    color: #292929;
    margin-bottom: 0;
  }
  &-detail {
    color: #888888;
    width: 100%;
    font-size: 14px;
    margin-bottom: 1vh;
    font-weight: 300;
  }
}
.highlights-hover-sec:hover .highlights-btns-hover {
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}
.highlights-hover-sec {
  position: relative;
  width: 490px;
  height: 270px;
  .highlights-btns-hover {
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background: #fee6e6;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    .highlights-btns {
      .ant-btn {
        &:last-child {
          margin-bottom: 0px;
        }
        &:hover {
          border-color: #ff246e;
          color: #ff246e;
        }
        display: flex;
        min-width: 160px;
        align-items: center;
        justify-content: center;
        background: transparent;
        border-color: #292929;
        color: #292929;
        margin-bottom: 20px;
        border-width: 2px;
        font-weight: 500;
        height: auto;
        padding: 6px 0;
      }
    }
  }
}
.s-catalogue-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  h3 {
    margin: 0;
    font-size: 36px;
  }
  .inventory-bottom-filter {
    margin-bottom: 0px;
  }
}
.s-catalogues-text {
  h3 {
    color: #292929;
    font-size: 20px;
  }
  h4 {
    font-size: 16px;
    font-weight: 400;
    span {
      color: #292929;
      font-weight: 600;
    }
  }
}
.s-catalouge-btns {
  .ant-btn {
    margin-right: 30px;
  }
}
.add-new-cata {
  .ant-upload-select-picture-card {
    height: 100% !important;
    padding: 4.4rem 0;
  }
}
.add-asset-sec-block {
  // position: relative;
  position: absolute;
  background-color: #ebebeb;
  border-radius: 6px;
  display: grid;
  align-items: center;
  justify-content: center;
  // padding: 4.4rem;
  // padding: 2.1rem;
  // max-width: 440px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  z-index: 1;
  // padding: 13%;
  &:hover {
    background-color: rgba(255, 36, 110, 0.05);
    .promotional-info {
      display: block;
    }
    .plusoutline-box {
      display: none;
    }
  }
  .anticon-plus {
    background-color: #dbdbdb;
    width: 6vw;
    height: 6vw;
    margin: auto;
    border-radius: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 30px;
    justify-content: center;
    color: #888888;
  }
  .promotional-info {
    color: #292929;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    display: none;
    height: 150px;
    margin-bottom: 5px;
  }
}
.general-Cate-search-bar {
  h3 {
    font-size: 26px;
  }
  .inventory-search-bar {
    width: 290px;
  }
}
.special-cat {
  h2 {
    font-size: 26px;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 16px;
  }
}
.colum-border {
  border-right: 1px solid #ebebeb;
}
.import-asset-menu {
  .active {
    color: #ff246e !important;
  }
  .ant-btn {
    padding: 0 0;
    color: #a5a5a5;
    position: relative;
    &:last-child {
      &::after {
        display: none;
      }
    }
    &::after {
      content: ">";
      position: absolute;
      color: #a5a5a5;
      right: -15px;
    }
    &:active,
    &:focus {
      color: #ff246e;
    }
  }
}
.f-u-success {
  h3 {
    font-size: 24px !important;
  }
}
.import-file-button-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  .ant-progress {
    margin-bottom: 15px;
  }
  .anticon-file-done {
    font-size: 70px;
    color: #ff246e;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 27px;
    color: #292929;
    margin-bottom: 2px;
  }
  p {
    margin: 0;
    color: #292929;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .ant-btn {
    margin-top: 18px;
    width: auto;
    min-width: 140px;
    height: auto;
    padding: 8px;
  }
}
.upload-csv-file-sec {
  height: 100%;
  .ant-upload-list-picture {
    display: none;
  }
  .ant-upload-drag {
    background-color: transparent;
    border: 0px;
  }
  .ant-upload.ant-upload-select-picture-card {
    height: calc(100vh - 265px);
    background-color: transparent !important;
  }
}
.uploading-csv-file-sec {
  height: 100%;
  .uploading-csv-file-info {
    display: grid;
    color: #292929;
    font-size: 14px;
    span {
      font-size: 3.5rem;
      font-weight: 500;
    }
  }
  .ant-progress {
    .anticon-check {
      color: #ff246e;
      font-size: 4rem;
    }
  }
}
.s-mapping-preset-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .trading-task-detail .back-button .anticon {
    border: 0px;
    border: 0px;
    font-size: 13px;
  }
  .trading-task-detail {
    width: auto;
    margin: 0px;
    margin-right: 30px;
    .preset-btn {
      svg {
        width: 14px;
        margin-right: 4px;
      }
    }
    .back-button {
      margin-bottom: 0;
      font-size: 12px;
      > span {
        text-decoration: underline;
      }
    }
  }
}
.columns-mapping-right {
  .header-btn-groups {
    display: flex;
    .ant-btn {
      width: 100%;
      margin: 0 5px;
    }
  }
  .mapping-right-item {
    margin-bottom: 2rem;
    .s-m-preset {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 12px;
        color: #ff246e;
        text-decoration: underline;
        cursor: pointer;
        .anticon {
          margin-right: 5px;
        }
      }
    }
    h5 {
      font-size: 16px;
      color: #ff246e;
      margin: 0;
    }
    h3 {
      font-size: 20px;
      color: #292929;
      margin: 0;
    }
    p {
      font-size: 14px;
      color: #292929;
      margin: 0;
    }
  }
}
.tab-error-sec {
  display: flex;
  align-items: center;
  .tab-error-found {
    .ant-btn-link {
      color: #ff246e;
      display: flex;
      align-items: center;
      .anticon {
        color: #ff246e;
        position: relative;
        left: 0;
        top: 0;
      }
      svg {
        fill: #ff246e;
        position: relative;
        left: 0;
        top: 0;
        margin-right: 4px;
      }
    }
  }
}
.metadata-tab-active {
  .tab-error-sec {
    .tab-error-found {
      .ant-btn-link {
        color: #ffffff;
        svg {
          fill: #ffffff;
        }
        .anticon {
          color: #ffffff;
        }
      }
    }
  }
}

.map-to-sec {
  .map-to {
    display: flex;
    align-items: center;
  }
  width: 60%;
  display: flex;
  align-items: center;
  span {
    color: #888888;
    margin-right: 1rem;
  }
  .map-select-colmn {
    display: flex;
    align-items: center;
    color: #292929;
    cursor: pointer;
    .anticon {
      color: #292929;
      font-size: 10px;
      margin-left: 8px;
    }
  }
}

.select-colum-modal {
  .add-selection-filter {
    h2 {
      font-size: 24px;
    }
  }
}

.error-info-pink {
  background-color: #fee6e6 !important;
  .error-message {
    color: #ff246e;
    float: right;
    width: 61%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.c-m-title {
  border: 0px !important;
  background-color: transparent;
  padding: 15px 35px 15px;
}
.mapping-right-item-gray {
  h5,
  h3,
  p {
    color: #9e9e9e !important;
  }
}
.side-bar-sec {
  .ant-menu-root {
    overflow-y: scroll;
    height: calc(100vh - 180px);
  }
}

.side-bar-sec .ant-menu-root::-webkit-scrollbar {
  width: 4px;
  height: 60px;
  margin-left: 10px;
}
.side-bar-sec .ant-menu-root::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.side-bar-sec .ant-menu-root::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 30px;
  background: rgba(240, 242, 245, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.side-bar-sec .ant-menu-root::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(203, 203, 204, 0.6);
}
.mapping-tip {
  p {
    color: #292929;
    margin-bottom: 1.4rem;
  }
}
.error-message-correction {
  color: #292929;
}
.s-c-add-btn {
  max-width: 100%;
}
.save-preset-btn {
  color: #ff246e;
  span {
    text-decoration: underline;
  }
  .anticon {
    margin-right: 2px;
  }
}
.room-persons-title {
  padding: 25px 24px 0;
}
.room-persons-sec {
  padding: 25px 24px 10px;
  display: flex;
  .anticon {
    width: 50px;
    height: 50px;
    font-size: 50px;
    color: #ececec;
    margin-bottom: 3px;
  }
  .room-person {
    text-align: center;
    min-width: 100px;
    cursor: pointer;
    &:first-child {
      border-right: 2px solid #ececec;
      min-width: 130px;
    }

    .ant-badge {
      margin-bottom: 3px;
      .ant-badge-dot {
        top: 7px;
        right: 7px;
        background: #28b93b;
      }
    }
    p {
      margin: 0;
      line-height: 14px;
      color: #292929;
    }
    h5 {
      margin: 0;
      line-height: 14px;
    }
    .ant-btn {
      color: #ff246e;
      font-size: 12px;
      padding: 0;
      height: auto;
    }
  }
}
.l-a-section {
  .l-a-file {
    .ant-btn {
      border-color: transparent;
      color: #ff246e;
      height: auto;
      padding: 7px 0;
      margin-right: 10px;
      font-size: 16px;
      display: flex;
      align-items: center;
      svg {
        width: 14px;
        margin-right: 7px;
      }
      span {
        text-decoration: underline;
      }
    }
  }
}
.invite-checkbox {
  padding: 0;
  max-height: 225px;
  height: 225px;
  min-height: 225px;
  margin-bottom: 20px;
  li {
    margin-bottom: 5px;
    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      .ant-checkbox {
        top: 1px;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #ff246e;
      border-color: #ff246e;
    }
    .ant-checkbox-checked::after {
      border: 1px solid #ff246e;
    }
  }
}
.close-agreement-btns {
  padding: 10px 25px;
  display: flex;
  justify-content: space-between;
  .ant-btn-link {
    padding-right: 0;
    padding-left: 0px;
    margin-left: 20px;
    color: #ff246e;
  }
}
.signature-btn-sec {
  padding: 24px 25px;
  h5 {
    font-size: 16px;
  }
  .close-agreement-btns {
    padding: 20px 0;
    .ant-btn-link {
      margin: 0;
      padding: 0;
      margin-right: 20px;
    }
  }
  .signature-agreement-btns {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .signature-select {
      margin-bottom: 8px;
      .ant-select-selector {
        background-color: #ebebeb;
        border: 0px;
      }
      .ant-select {
        margin-right: 8px;
      }
    }
  }
}
.sign-now-modal {
  .sign-key {
    background: #ebebeb;
    padding: 15px;
    border-radius: 6px;

    margin-bottom: 10px;
    span {
      color: #a3a3a3;
    }
  }
  p {
    color: #292929;
  }
  .ant-btn {
    width: 100%;
  }
}

.tabs-check-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 45px;
  position: relative;
  .anticon {
    color: #888888;
    position: absolute;
    right: 0px;
    top: 7px;
  }
}

.slecting-columns {
  margin: 0;
  .ant-radio-button-wrapper {
    &:hover {
      color: #ff246e;
    }
    &:focus-within {
      box-shadow: none;
    }
  }
}
.upload-iscc-btn {
  .ant-upload-list-text {
    display: none;
  }
}
.proceed-btn {
  padding: 7px 32px;
  height: auto;
  &:hover {
    border-color: #ff246e;
    color: #ff246e;
  }
}
.l-p-list {
  max-height: 195px;
  height: 195px;
  min-height: 195px;
}
.s-c-filterbar {
  .ant-input-affix-wrapper {
    background-color: transparent;
    .ant-input {
      background-color: transparent;
    }
  }
}
.a-import-ss {
  .ant-progress-inner {
    width: 250px !important;
    height: 250px !important;
  }
}
.task-table {
  .ant-table-thead {
    .ant-table-cell:last-child {
      max-width: 111px;
    }
  }
}

.underline-ownership {
  color: #ff246e !important;
  text-decoration: underline;
}

.title-icon-flex {
  display: flex;

  .inventory-title-icon {
    display: inline-grid;
    font-size: 10px;
    margin: 3px 5px;

    .mt-disasendingorder {
      margin-top: -6px;
    }
  }
}

.main-episode {
  .space-between {
    justify-content: space-between;
    .radio-btn-episode {
      border-radius: 10px;

      .ant-select-arrow svg {
        color: #ff336a;
      }
    }
  }
}

.width-30 {
  width: 30%;
}
.width-70 {
  width: 70%;
}

.main-episodes-list {
  .episode-padding {
    padding: 10px;
  }
  .bg-grey {
    background-color: #f1f1f1;
    border-radius: 10px;
    margin-bottom: 25px;
  }
  .episode-image-style img {
    border-radius: 10px;
    width: 100%;
  }
  .episode-description-padding {
    padding-left: 20px;
  }
  .launch-style {
    padding-right: 70px;

    .launch-title h4 {
      margin-bottom: 0;
    }
    .launch-date-style p {
      margin-bottom: 0;
    }
  }
  .length-style {
    padding-right: 70px;

    .length-title h4 {
      margin-bottom: 0;
    }
    .length-mins-style p {
      margin-bottom: 0;
    }
  }
  .director-style {
    .director-title h4 {
      margin-bottom: 0;
    }
    .director-name-style p {
      margin-bottom: 0;
    }
  }
}
.ant-modal-body {
  .width-video div {
    width: 849px !important;
    height: 475px !important;

    .react-player__preview {
      .react-player__shadow div {
        border-color: transparent transparent transparent transparent !important;
        margin-left: 0px !important;
      }
    }
  }
}

.email-style {
  color: #2d2d2d;
}

.badge-dot-style .ant-badge-status-dot {
  border: 1px solid #c5c5c5;
}

.proposal-tabs {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 10px 19px;
  margin-bottom: 15px;
  cursor: pointer;
  border: 1px solid #888888;
  justify-content: space-between;
  h3 {
    color: #222;
    margin-bottom: 0em;
  }
  button {
    color: #222 !important;
  }
  span {
    color: #222;
  }
}

.proposal-tabs-active {
  background: transparent
  linear-gradient(270deg, #ff5e5e 0%, #ff246e 100%) 0% 0% no-repeat
  padding-box;
  border: 1px solid #ff246e;
  h3 {
    color: white;
  }
  button {
    color: white !important;
  }
  span {
    color: white;
  }
}

.panel-style-trading {
  .ant-collapse-arrow {
    display: none !important;
  }

  .rightout-style {
    float: right;
  }
  .ant-row {
    width: 100%;
  }
}

.upload-btn-style {
  display: flex;
  text-align: center;

  .m-10 {
    margin: 5px 10px;
  }

  .ant-upload-list-item {
    display: none;
  }
}

.ant-tooltip-open {
  z-index: 1000000 !important;
}

.preview-image {
  height: 340px;
}

.add-asset-sec-block {
  // height: 190.375px;
  .plusoutline-box {
    display: block;
    // padding: 20px 0px;
    text-align: center;
    &:hover {
      display: none;
    }
  }

  p {
    font-size: 14px;
    color: #292929;
    margin: 6px 0px 0px 0px;
    @media only screen and (max-width: 1200px) {
      font-size: 10px;
    }
    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
  .anticon-plus {
    width: 6vw;
    height: 6vw;
    margin: 0 auto;
    margin-bottom: unset;
    @media only screen and (max-width: 1200px) {
      width: 4vw;
      height: 4vw;
    }
    @media only screen and (max-width: 625px) {
      width: 2vw;
      height: 2vw;
    }
  }
  .promotional-info {
    height: unset;
    margin-bottom: unset;
    .ant-col {
      padding: 8px 0px;
      .ant-btn {
        background: none;
        border-color: rgba(0, 0, 0, 0.85);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 18px 32px;
        @media only screen and (max-width: 1200px) {
          padding: 6px 24px;
        }
        @media only screen and (max-width: 1080px) {
          padding: 0px 14px;
          height: 24px;
        }
        @media only screen and (max-width: 950px) {
          padding: 0px 14px;
          height: 16px;
        }
        span {
          color: rgba(0, 0, 0, 0.85);
          font-size: 16px;
          @media only screen and (max-width: 1200px) {
            font-size: 14px;
          }
          @media only screen and (max-width: 1080px) {
            font-size: 8px;
          }
        }
        &:hover {
          // color: rgba(0, 0, 0, 0.85);
          color: #ff246e;
          background: transparent;
          border-color: #ff246e;
          span {
            color: #ff246e;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
        }
      }
    }
  }
}

.csv-upload {
  &-circleprogress {
    text-align: center;
    &-area {
      display: table-cell;
      vertical-align: middle;
      &-parent {
        display: table;
        height: 400px;
        overflow: hidden;
        margin: auto;
      }
      .ant-progress-text2 {
        position: relative;
        top: -59px;
        font-size: 8px;
        font-weight: 600;
        color: #000000;
        z-index: 152;
        margin-bottom: 0px;
      }
    }
    .ant-progress-circle .ant-progress-text {
      font-weight: bold;
    }

    .ant-progress-circle.ant-progress-status-success .ant-progress-text {
      color: #ff246e;
      font-weight: bold;
      font-size: 1.5em;
    }
    .ant-upload-text {
      font-size: 18px;
      color: #000000;
      font-weight: 600;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .ant-upload-hint {
      color: #000000;
      font-weight: 500;
      font-size: 10px;
      margin-bottom: 10px;
    }
  }
  .ant-upload.ant-upload-drag {
    background: unset;
    border: unset;
    margin: 0px auto;
    // width: 40%;
    .ant-upload {
      padding: unset;
      display: table;
      height: 400px;
      overflow: hidden;
      margin: auto;
    }
    .ant-upload-text {
      font-size: 32px;
      color: #000000;
      // font-weight: 600;
      margin-top: 5px;
      margin-bottom: 0px;
    }
    .ant-upload-hint {
      color: #000000;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  &-detail {
    text-align: center;
  }
  button {
    margin-top: 10px;
  }
  button.ant-btn {
    height: 40px;
    width: 216px;
    font-size: 16px;
  }

  .add-asset-icon {
    height: 100px;
    width: 85px;
    margin: 0px auto;
  }
  .ant-upload-list-text {
    display: none;
  }
  .draggerAlign {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 66vh;
  }
}

.new-items-info {
  margin-top: 1.5vh;
  h3 {
    font-size: 20px;
    margin: 0px;
    font-weight: 400;
    text-transform: capitalize;
  }
  h3 {
    margin: 0px;
  }
}
.new-items-grid {
  img {
    border-radius: 6px;
  }
  &-img {
    position: relative;
    text-align: center;
    color: white;
    &-overlay {
      position: absolute;
      top: 0;
      width: 100%;
      text-align: left;
      // background: linear-gradient(to left, transparent 0%, black 75%);
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 40%,
        rgba(0, 0, 0, 0) 70%,
        rgba(255, 255, 255, 0) 100%
      );
      height: 100%;
      border-radius: 6px;
      // padding-left: 1rem;
      // opacity: 0.7;
    }
    &-text {
      position: absolute;
      top: 50%;
      // left: 15%;
      transform: translate(0%, -50%);
      width: 50%;
      word-wrap: break-word;
      font-size: 26px;
      font-weight: 600;
      padding-left: 30px;
      color: #ffffff;
      text-align: left;
      text-transform: capitalize;
    }
  }
}

.ant-menu-submenu-arrow-list {
  top: 40% !important;
  transform: rotate(90deg);
}

.catalogue-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  // height: 190.375px;
  border-radius: 6px;
  // background-blend-mode: overlay;
  img {
    opacity: 0;
  }
}

.catalogue-bg-overlay {
}

.add-asset-sec-plus-block {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #ebebeb;
  border-radius: 6px;
  display: grid;
  align-items: center;
  justify-content: center;
  // padding: 4.4rem;
  // padding: 2.1rem;
  // max-width: 440px;
  // padding: 13%;
  cursor: pointer;
  .anticon-plus {
    background-color: #dbdbdb;
    width: 6vw;
    height: 6vw;
    margin: auto;
    border-radius: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 30px;
    justify-content: center;
    color: #888888;
    @media only screen and (max-width: 1200px) {
      width: 4vw;
      height: 4vw;
    }
    @media only screen and (max-width: 800px) {
      margin-bottom: 0px;
    }
    @media only screen and (max-width: 600px) {
      width: 2vw;
      height: 2vw;
    }
  }
  .promotional-info {
    display: flex;
    justify-content: center;
    font-size: 18px;
    @media only screen and (max-width: 1200px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 950px) {
      font-size: 10px;
    }
    @media only screen and (max-width: 800px) {
      display: none;
    }
  }
}

.plus-icon-maintain {
  position: relative;
  width: 100%;
  &:hover {
    .add-asset-sec-block {
      background-color: rgba(255, 36, 110, 0.05);
      .promotional-info {
        display: block;
      }
      .plusoutline-box {
        display: none;
      }
    }
  }
  img {
    opacity: 0;
    width: 100%;
  }
}

.maintain-add-asset-sec-plus-block {
  position: relative;
  cursor: pointer;
  img {
    opacity: 0;
  }
}

.organization-p-body {
  .edit-profile-textarea .full-width {
    margin: 2px 0px;
  }
  .formal-license-data .ant-upload.ant-upload-select-picture-card {
    margin-left: unset;
  }
  .formal-license-view .formal-license-row .formal-license-name p {
    margin-bottom: unset;
  }
  .ant-upload-list-picture-card {
    &-container {
      margin: 0 0px 8px 0;
    }
    .ant-upload-list-item {
      margin: 5px 0 0px 0px;
      &-thumbnail img {
        object-fit: cover;
      }
      &-info {
        border-radius: 6px;
      }
    }
  }
}
.f-artist-com {
  margin-right: 5px;
  &:last-child {
    span {
      display: none;
    }
  }
}

.empty-icon {
  .ant-empty {
    margin-top: 10rem;
  }
}

.content-center {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.buttons-link-style {
  justify-content: space-between;
  margin-top: 2rem;

  .primary-link-btn a .anticon {
    margin-left: 0px;
  }
}
.iscc-generated {
  padding: 4px 11px;
  border-radius: 6px;
  border: 1px solid #c7c7c7;
}
.ant-picker {
  border: 1px solid #c7c7c7;
}
.mb12 {
  margin-bottom: 12px;
}
.makeDisabled {
  pointer-events: none;
  opacity: 0.6;
}

.inlineflex {
  display: inline-flex;
}

.overflowDots {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.inlineProperty {
  display: inline;
}
.f-artist-com .inlineProperty span {
  display: inline;
}
.f-artist-com .inlineProperty:last-child span {
  display: none;
}

.permissions-data {
  &-label {
    font-weight: 700;
  }
  &-value {
    display: flex;
  }
  &-help {
    margin-left: 8px;
    display: none;
  }
}
.permissions-data:hover {
  .permissions-data-help {
    display: block;
  }
}

.select-permission {
  margin-bottom: 20px;
}

.ant-layout-header {
  line-height: 10px;
}

.upload-iscc-btn {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .upload-msg {
    margin-top: 1rem;
  }
  .ant-progress-small.ant-progress-line {
    font-size: 14px;
    .ant-progress-inner {
      background-color: #ebebeb;

      .ant-progress-bg {
        background-color: #ff246e !important;
      }
    }
  }
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Select some files";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #888888;
  border-radius: 30px;
  padding: 8px 30px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 600;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  color: #ffffff;
  background: #ff246e;
  border-color: #ff246e;
  font-weight: 500 !important;
  outline: none;

  white-space: nowrap;
}

.color-grey {
  color: grey;
  font-size: 16px;
}

.mr-15 {
  margin-right: 15px;
}

.license-menu {
  .ant-menu-submenu-open.ant-menu-submenu-inline
    > .ant-menu-submenu-title
    > .ant-menu-submenu-arrow::before {
    transform: rotate(-45deg) translateX(-2.5px) !important;
  }
}

.iscc-merge-buttons {
  &-upload {
    display: inline-block;
    margin-right: 10px;
  }
  &-info {
    display: inline-block;
    &-content {
      list-style: disc;
      &-heading {
        margin-bottom: 10px;
      }
    }
  }
}

.new-catalogues-grid {
  .container {
    max-width: 500px;
  }

  .image-wrapper {
    position: relative;
    padding-bottom: 56.2%;
    img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
.style-image-grid,
.asset-detial-video {
  .container {
    max-width: 500px;
  }

  .image-wrapper {
    position: relative;
    padding-bottom: 56.2%;
    img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.preview-image {
  .container {
    max-width: 100%;
  }

  .image-wrapper {
    position: relative;
    padding-bottom: 56.2%;
    img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.ml-20 {
  margin-left: 20px;
}

.tabs-style {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
    background: #ff246e;
    padding: 5px 15px;
    border-bottom: #ff246e;
  }
  .ant-tabs-ink-bar-animated {
    display: none;
  }

  .btn-grey {
    color: grey !important;
  }
  .d-flex {
    .url-form {
      width: 100%;
    }
  }
}
.update-asset-btn {
  .detail-btn {
    text-transform: uppercase;
    padding: 8px 30px;
    height: auto;
  }
}

// Embed Player

.embed-video-player {
  .play-btn {
    position: absolute;
    display: flex;
    align-items: center;
    top: 45%;
    left: 42%;
    z-index: 10;
    font-weight: 700;
    border-radius: 20px;
    color: white;
    padding: 22px 35px 22px 27px;
    font-size: 18px;
    .play-icon {
      font-size: 30px;
      padding-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .video-player {
    position: relative;
    z-index: 20;
  }
  .detail-model
    .ant-modal-body
    .asset-detail-model
    .asset-model-info
    .lang-btn.preview-modal-style
    .ant-radio-button-wrapper {
    display: block;
    height: unset;
    padding: 0px 2px;
    text-align: center;
  }
}

.main-asset-list {
  .position-fixed {
    z-index: 100;
    position: fixed;
    background: white;
    width: calc(100% - 349px);
    top: 89px;
  }

  .ant-spin-nested-loading {
    margin-top: 74px;
  }
}
.sign-text-icon {
  display: flex;
  align-items: center;
  .icon-style {
    font-size: 17px;
  }
  .sign-text {
    padding-left: 10px;
  }
}
