@import url(./Fonts/style.css);
body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // font-family: "SF Pro Display Regular"
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 16px;
}

p {
  color: #969696;
  font-size: 13px;
}

.h-auto {
  height: auto;
}

.d-flex {
  display: flex;
}
.d-block {
  display: block !important;
}
.d-none {
  display: none;
}
.align-item-center {
  align-items: center;
}

.align-item-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.flex-start {
  justify-content: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-end-imp {
  justify-content: flex-end !important;
}

.space-between {
  justify-content: space-between;
}

.center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.border-0 {
  border: 0;
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-bottom-2 {
  border-bottom: solid 2px #eeeeef;
}

.border-bottom-1 {
  border-bottom: solid 1px #eeeeef;
}

.border-top-0 {
  border-top: 0;
}

.border-top-2 {
  border-top: solid 2px #eeeeef;
}

.border-top-1 {
  border-top: solid 1px #eeeeef;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
  line-height: 24px;
}

.font-16 {
  font-size: 16px;
  line-height: 28px;
}

.font-18 {
  font-size: 18px;
  line-height: 32px;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-300 {
  font-weight: 300;
}

.color-white {
  color: #ffffff !important;
}

.white-bg {
  background: #ffffff;
}

.grey-bg {
  background: #f9f9f5;
}

.pt0 {
  padding-top: 0;
}

.pr0 {
  padding-right: 0;
}

.pr-50 {
  padding-right: 50px;
}

.pl0 {
  padding-left: 0;
}

.pb0 {
  padding-bottom: 0;
}

.p10 {
  padding: 10px;
}

.p15 {
  padding: 15px;
}

.p20 {
  padding: 20px;
}

.p30 {
  padding: 30px;
}

.p24 {
  padding: 24px;
}

.p30 {
  padding: 30px;
}

.p40 {
  padding: 30px;
}

.pl10 {
  padding-left: 10px;
}

.pl15 {
  padding-left: 15px;
}

.pl20 {
  padding-left: 20px;
}

.pl30 {
  padding-left: 30px;
}

.pl24 {
  padding-left: 24px;
}

.pl30 {
  padding-left: 30px;
}

.pl40 {
  padding-left: 30px;
}

.pr10 {
  padding-right: 10px;
}

.pr15 {
  padding-right: 15px;
}

.pr20 {
  padding-right: 20px;
}

.pr30 {
  padding-right: 30px;
}

.pr24 {
  padding-right: 24px;
}

.pr30 {
  padding-right: 30px;
}

.pr40 {
  padding-right: 30px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb50 {
  padding-bottom: 50px;
}

.pb60 {
  padding-bottom: 60px;
}

.pb70 {
  padding-bottom: 70px;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pt20_imp {
  padding-top: 20px !important;
}

.pt30 {
  padding-top: 30px;
}

.mt72 {
  margin-top: 72px;
}

.mt0 {
  margin-top: 0;
}

.mt0-imp {
  margin-top: 0 !important;
}

.mr60 {
  margin-right: 60px;
}

.mr0 {
  margin-right: 0;
}
.ml0 {
  margin-left: 0;
}

.m10 {
  margin: 10px;
}

.m15 {
  margin: 15px;
}

.m20 {
  margin: 20px;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

.ml40 {
  margin-left: 40px;
}

.ml50 {
  margin-left: 50px;
}

.ml100 {
  margin-left: 100px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.mr40 {
  margin-right: 40px;
}

.mr5rem {
  margin-right: 5rem;
}

.mr4rem {
  margin-right: 4rem;
}
.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb20-imp {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb5-imp {
  margin-bottom:5px !important;
}

.mb40 {
  margin-bottom: 40px;
}

.mb40-imp {
  margin-bottom: 40px !important;
}

.mb25-imp {
  margin-bottom: 25px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mlr10 {
  margin: 0px 10px;
}

.mlr15 {
  margin: 0px 15px;
}

.p0 {
  padding: 0;
}

.pl2px {
  padding-left: 2px !important;
}

// .pl0_important {
//   padding-left: 0px !important;
// }

.plr10 {
  padding: 0px 10px;
}

.pd-side {
  padding: 0 15px;
}

.mr10 {
  margin-right: 10px;
}

.lft {
  float: left;
}

.ml10 {
  margin-left: 10px;
}

.rit {
  float: right;
}

.margin--24 {
  margin: -24px;
}

.full-width {
  width: 100%;
}

.full-vh-height {
  height: 100vh;
}

.full-per-height {
  height: 100%;
}

.w10-per {
  width: 10%;
}

.w15-per {
  width: 15%;
}

.w20-per {
  width: 20%;
}

.w25-per {
  width: 25%;
}

.w30-per {
  width: 30%;
}

.w33-per {
  width: 33%;
}

.w35-per {
  width: 35%;
}

.w40-per {
  width: 40%;
}

.w50-per {
  width: 50%;
}
.w54-per {
  width: 54%;
}
.w50-per-imp {
  width: 50% !important;
}
mb25-imp

.width-100 {
  width: 100%;
  max-width: 100px;
}
.min-width100 {
  min-width: 100%;
}
.width-150 {
  width: 100%;
  max-width: 150px;
}

.width-200 {
  width: 100%;
  max-width: 200px;
}

.width-250 {
  width: 100%;
  max-width: 250px;
}

.width-300 {
  width: 100%;
  max-width: 300px;
}

.width-350 {
  width: 100%;
  max-width: 350px;
}

.width-400 {
  width: 100%;
  max-width: 400px;
}

.width-500 {
  width: 100%;
  max-width: 500px;
}

.width-600 {
  width: 100%;
  max-width: 600px;
}

.empty-data {
  text-align: center;
  margin: 50px 0;
}

.empty-data img {
  width: 100%;
  max-width: 600px;
}

.empty-data h3 {
  font-size: 22px;
}

.pagination-style {
  text-align: center;
  margin-top: 20px;
}

.main-wrapper {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 40px 0;
}

.filter-style {
  margin-bottom: 20px;
  text-align: center;
}

.filter-style .ant-input-affix-wrapper {
  width: 400px;
  border-radius: 4px;
}

.movie-box {
  background-color: #ffffff;
  border: solid 1px #f1f1f1;
  margin-bottom: 30px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 15px #dcdcdcb5;
}

.movie-box img {
  width: 100%;
}

.movie-box .caption {
  padding: 30px 20px;
}

.movie-box .caption p:last-child {
  margin-bottom: 0;
}

.details-list-style .ant-col {
  margin-bottom: 15px;
}

.rating-box {
  border: solid 1px #f1f1f1;
  padding: 40px 20px;
  text-align: center;
  margin: 10px;
}

.rating-box p {
  margin-bottom: 5px;
}

.rating-box h3 {
  margin-bottom: 0;
  font-size: 22px;
}
.required {
  span {
    position: relative;
    &::before {
      content: "*";
      position: absolute;
      right: -8px;
      color: red;
      top: -3px;
    }
  }
}

/******* Radio Button Style ********/
.radio-btn-style {
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  .ant-radio-button-wrapper {
    border-radius: 30px;
    margin-right: 10px;
    border-left-width: 1px;
    &:last-child {
      margin: 0;
    }
  }
}
/********* Chat APP *********/

/* #container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: "sidebar main";
  width: 100vw;
  height: 100vh;
}
#main {
  grid-area: main;
}
#new-message {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 5px;
  margin-left: 0px;
  border-top: 1px solid #3f3f3f;
}
#messages-list {
  padding: 5px 0 0 5px;
}
#sidebar {
  grid-area: sidebar;
  padding: 5px 0 0 5px;
  border-right: 1px solid #3f3f3f;
  height: 100%;
} */

/* new */

/* #new-message {
  padding: 0px;
  height: 50px;
}

#new-message input {
  font-family: "Press Start 2P";
  width: 100%;
  height: 100%;
  margin: 0;
  height: 50px;
  padding: 5px;
} */

.ant-layout-footer {
  padding: 15px 24px;
}
.site-layout {
  margin-top: 64px;
}
.ant-layout-sider {
  position: fixed;
  height: 100%;
  z-index: 100;
}
.site-content {
  margin-left: 301px;
}
.ant-layout-footer {
  position: relative;
  z-index: 100;
}
.ant-dropdown, .ant-popover {
z-index: 10000;
}
div[disabled]
{
  pointer-events: no-drop;
  opacity: 0.5;
  cursor: no-drop;
}
a[disabled]
{
  pointer-events: no-drop;
  opacity: 0.5;
  cursor: no-drop;
}

// .ant-select-multiple{
//   background-color: #ececec;
//     border-radius: 6px;
//   &::before {
//     content: ">";
//       position: absolute;
//       right: 10px;
//       top: 4px;
//       transform: rotate(90deg);
//       color: #292929;
//   }
// }

.ant-select-multiple {
  box-sizing: border-box;
  position: relative;
  display: block;
 }
 
 .ant-select-multiple::before {
  content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6.8px;
    height: 6.8px;
    transform: rotate(135deg);
    color: #292929;
 }
 
 .ant-select-multiple::before {
  border-top: 1px solid;
    border-right: 1px solid;
    top: 50%;
    right: 14px;
    margin-top: -5px;
 } 