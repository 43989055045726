
$orangeColor: #FF5E5E;
$pinkColor: #FF246E;
$whiteColor: #ffffff;
$blackColor: #000000;
$grayColor: #888888;
$darkGray: #292929;
$tableLightBg: #ebebeb;
$tableDarkBg: #DBDBDB;
$layoutBorder: #C7C7C7;

$boxBgColor: #f3f3f3;

a {
  color: $pinkColor;
}

a:hover {
  color: $blackColor;
}
::placeholder {
  color: $grayColor;
}

:-ms-input-placeholder {
  color: $grayColor;
}

::-ms-input-placeholder {
  color: $grayColor;
}
.ant-input:hover, .ant-input:focus, .ant-input-focused, .ant-select, .ant-select:not(.ant-select-disabled) .ant-select-selector, .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  // border-color: $pinkColor;

  border-radius: 6px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #c7c7c7;
}
.ant-input, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-select-multiple .ant-select-selector {
  // border-color: $grayColor;
  border-color: #c7c7c7;
  color: $darkGray;
}
.add-select-update {
  button {
    color: $pinkColor;
    &:hover {
      color: $darkGray;
    }
    &:focus {
      color: $pinkColor;
    }
  }
}
////////  Radio Button Style ///////////
.ant-radio-inner::after {
  background-color: $pinkColor;
}
.ant-radio-checked .ant-radio-inner, .ant-radio-checked .ant-radio-inner, .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: $pinkColor;

}
////////  Radio Button Style ///////////
.my-market-inventory {
  border-bottom: 1px solid $layoutBorder;
}
.ant-layout-header {
  border-bottom: 1px solid $layoutBorder;
}
.top-bar-notification .ant-badge-count {
  background: transparent linear-gradient(270deg, $orangeColor 0%, $pinkColor 100%) 0% 0% no-repeat padding-box;

}
.ant-layout-sider {
  background: $whiteColor;
  border-right: 1px solid $layoutBorder;
  border-right: 1px solid $layoutBorder;
}
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper:hover, .ant-input-affix-wrapper-focused {
  border-color: $pinkColor;

}
.darkgray-btn {
  border-color: $darkGray;
  color: $darkGray;
  &:hover, &:focus, &:active {
    border-color: $darkGray;
    color: $darkGray;
  }
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
  border-color: $pinkColor;

}
.table-row-dark {
  background-color: $tableLightBg;
}
.table-row-light {
  background-color: $whiteColor;
}
.table-style {
  .ant-tag {
    border-color: $darkGray;
    color: $darkGray;
  }
  .ant-table-thead {
    tr {
      th {
        background: $tableDarkBg;
      }
    }
  }
}
.inventory-search-tabs {
  span {
    border:1px solid #DBDBDB;
    color: $grayColor;
  }
  .inventory-result {
    button {
      padding: 0;
      svg {
        fill:$grayColor;
      }
    }
  }
}
.inventory-search-tabs {
  span.inventory-result {
    color: $darkGray;
    button:hover {
      &:hover {
        svg {
          fill: $grayColor;
        }
      }
    }
    svg {
      fill: $darkGray;
    }
  }
}
.inventory-select-filter {
  .ant-space-item {
    .anticon svg {
      fill: $grayColor;
    }
  }
}
.meta-link-avatar:hover .ant-card-meta-title {
  color: $pinkColor;
}
.table-style {
  .ant-table-cell {
    color: $darkGray;
    a {
      color: $darkGray;
      :hover {
        color: $pinkColor;
      }
    }
  }
  .action-table {
    .ant-space-item {
      a {
        color: $pinkColor;
      }
    }
    .ant-btn-link {
      color: $pinkColor !important;
    }
  }
  .table-action button {
    color: $pinkColor;

    &:hover {
      color: $blackColor;
    }
  }
}
.ant-pagination-item-active {
  a {
    color: $blackColor !important;
  }
  border-color: $blackColor;
}
.ant-pagination-item {
  a {
    color: #d9d9d9;
  }
}
  .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link, .ant-pagination-item:focus, .ant-pagination-item:hover, .ant-pagination-item:focus a, .ant-pagination-item:hover a {
  color: $blackColor;
    border-color: $blackColor;
}
  .submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background-color: $whiteColor;
  background-image: none;
  opacity: 1;
}
  .ant-menu-submenu-selected.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-selected.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background-color: $whiteColor;
  background-image: none;
  opacity: 1;
}
.ant-menu {
  li {
    svg {
      fill: $pinkColor;
    }
  }

  li.ant-menu-item-selected, li.ant-menu-submenu-selected {
    svg {
      fill: $whiteColor;
    }
  }
}

  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active {
    color: $pinkColor;

}
.ant-menu-submenu-active {
  .ant-menu-submenu-title {
    &:hover {
      color: $pinkColor;
    }

  }
}
.ant-menu-submenu-title {
  .anticon {
    color: $pinkColor;
  }
}
.ant-menu-submenu-selected {
  .ant-menu-submenu-title {
    background: transparent linear-gradient(270deg, $orangeColor 0%, $pinkColor 100%) 0% 0% no-repeat padding-box;

      color: $whiteColor;
    &:hover {
      color: $whiteColor;
    }

  }
  a {
    color: rgba(0, 0, 0, 0.65) !important;
    &:hover {
      color: $pinkColor !important;
    }
  }
  .ant-menu-item.ant-menu-item-selected {
    background: $whiteColor !important;
    color: $pinkColor !important;
    a {
      color: $pinkColor !important;
    }
  }

    .anticon {
      color: $whiteColor;
    }

    a {
      color: $whiteColor;
    }
  &:hover {
    .anticon {
      color: $whiteColor;
    }

    a {
      color: $whiteColor;
    }
  }

}

.ant-menu {
  .ant-menu-item.ant-menu-item-selected {
    background: transparent linear-gradient(270deg, $orangeColor 0%, $pinkColor 100%) 0% 0% no-repeat padding-box;
    color: #ffffff;
    .anticon {
      color: $whiteColor;
    }

    a {
      color: $whiteColor;
    }

    &:hover {
      .anticon {
        color: $whiteColor;
      }

      a {
        color: $whiteColor;
      }
    }
  }

  .ant-menu-item {
    .anticon {
      color: $pinkColor;
    }
    &:hover {
      .anticon {
        color: $pinkColor;
      }

      a {
        color: $pinkColor;
      }
    }
  }
}

/***** Inventory *****/
.ant-input-affix-wrapper {
  // border-color: $grayColor;
  border-color: #c7c7c7;
}
.layout-btn {
  .ant-radio-button-wrapper {
    border-color: $grayColor;
    span {
      svg {
        fill: $grayColor;
      }
    }
  }
  .ant-radio-button-wrapper-checked {
    span {
      svg {
        fill: $whiteColor;
      }
    }
  }
  button {
    border-color: $grayColor;
  }
}

.radio-btn-style {
  .ant-radio-button-wrapper {
    border-color: $grayColor;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: $whiteColor;
    background: transparent linear-gradient(270deg, $orangeColor 0%, $pinkColor 100%) 0% 0% no-repeat padding-box;
    border-color: $pinkColor;

  }
  .ant-radio-button-wrapper:hover {
    color: $blackColor;
  }
}
.ant-switch-checked {
  .ant-switch-handle::before {
    background: transparent linear-gradient(270deg, $orangeColor 0%, $pinkColor 100%) 0% 0% no-repeat padding-box;
  }
}
.ant-btn-default {
  &:hover {
    color: $pinkColor;
    background: transparent;
    border-color: $pinkColor;
  }
  &:focus {
    border-color: $grayColor;
    color: $grayColor;
  }
}
.ant-btn-primary {
  color: $whiteColor;
  background: transparent linear-gradient(270deg, $orangeColor 0%, $pinkColor 100%) 0% 0% no-repeat padding-box;
  border: none;
  &:hover, &:focus {
    background: transparent linear-gradient(270deg,  $pinkColor 0%,$orangeColor 100%) 0% 0% no-repeat padding-box;
  }

}
.duration {
  color: $pinkColor;
}
.selection-radio {
  li {
    button {
      color: $pinkColor;
      &:hover {
        color: $darkGray;
      }
      &:focus {
        color: $pinkColor;
      }
    }
  }
}

.new-label {
  background: transparent linear-gradient(270deg, $orangeColor 0%, $pinkColor 100%) 0% 0% no-repeat padding-box;
}
///////////     Inventory Selections      ////////////////
.inventory-slection-assets {
  .inventory-slection-asset {
    box-shadow: 0px 0px 3px 0px #C7C7C7;
    &:hover {
      background: transparent linear-gradient(270deg, $orangeColor 0%, $pinkColor 100%) 0% 0% no-repeat padding-box;
      .i-slection-name {
        color: $whiteColor;
      }
      .i-selecion-btn {
        .ant-btn {
          color: $whiteColor;
          &:hover {
            color: $darkGray;
          }
          .anticon {
            svg {
              color: $whiteColor;
            }
          }
        }
      }
    }
    .i-slection-name {
      color: $darkGray;
    }
    .i-selecion-btn {
      .ant-btn {
        color: $pinkColor;
        span {
          text-decoration: underline;
        }
        .anticon {
          border-bottom: 0px;
          svg {
            color: $grayColor;
          }
        }
      }
    }
  }
  .active {
    background: transparent linear-gradient(270deg, $orangeColor 0%, $pinkColor 100%) 0% 0% no-repeat padding-box;
    .i-slection-name {
      color: $whiteColor;
    }
    .i-selecion-btn {
      .ant-btn {
        color: $whiteColor;
        .anticon {
          svg {
            color: $whiteColor;
          }
        }
      }
    }
  }
}
.slection-asset-view {
  background-color: $boxBgColor;
  .formal-license-name {
    span {
      color: #000000;
    }
  }
  .formal-license-data {
    span {
      color: $darkGray;
    }
    .ant-tag {
      border-color: $darkGray;
      color: $darkGray;
      background-color: transparent;
    }
  }
  .formal-license-btn {
    display: flex;
    .ant-btn-link {
      color: $pinkColor !important;
    }
  }
}
.vendor-selection-header {
  .vendor-name {
    h3 {
      color: $darkGray;
    }
  }
  .request-p-p {
    button {
      color: $pinkColor;
      .anticon {
        color: $whiteColor;
        background: transparent linear-gradient(270deg, $orangeColor 0%, $pinkColor 100%) 0% 0% no-repeat padding-box;
      }
    }
  }
}

.add-item-menu {
  .anticon {
    background: transparent linear-gradient(270deg, $orangeColor 0%, $pinkColor 100%) 0% 0% no-repeat padding-box;

    svg {
      fill: $whiteColor !important;
    }
  }

  &:hover {
    color: $pinkColor;
  }
}

.negotiation-btns {
  .ant-radio-button-wrapper {
    border-radius: 6px;
  }
  .ant-btn {
    border-color: $grayColor;
  }
}
.contract-header {
  border-bottom: 1px solid $layoutBorder;
}

.contract-upload {
  .ant-upload {
    .ant-btn {
      border-color: $darkGray;
      color: $darkGray;
      .anticon {
        color: $darkGray;
      }
    }
  }
  .upload-history {
    color: $pinkColor;
  }
}
.License-assets-collapse {
  .formal-license-btn {
    .add-definition-btn {
      .anticon{
        background: transparent linear-gradient(270deg, $orangeColor 0%, $pinkColor 100%) 0% 0% no-repeat padding-box;

        color: $whiteColor;
      }
    }
  }
  .ant-collapse-item-active {
    .ant-collapse-header {
      background: transparent linear-gradient(270deg, #FF5E5E 0%, #FF246E 100%) 0% 0% no-repeat padding-box;
      border: 1px solid $pinkColor !important;

      color: $whiteColor;
    }
  }
}
.chat-style {
  .chat-searchbar {
    background-color: $boxBgColor;
    .ant-btn.send-msg-btn {
      background: transparent linear-gradient(270deg, $orangeColor 0%, $pinkColor 100%) 0% 0% no-repeat padding-box;
      color: $whiteColor;
    }
    .attached-btn {
      color: $grayColor;
    }
  }
}
.license-contract {
  &::after {
    content: "";
    background-color: #f3f3f3;
  }
}
.file-history {
  ul {
    li {
      border: 1px solid $grayColor;
      .file-history-name {
        span {
          color: $darkGray;
        }
        .anticon svg {
          color: $pinkColor;
        }
      }
      .file-history-download {
        button {
          .anticon svg {
            color: $darkGray;
          }
        }
      }
    }
  }
}

